import React from 'react'
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ?  "50%" : "90%",
    overflow:"scroll",
    height: "80vh",
    padding:"2em"
  },
};
export default function DynamicModal({isOpen,closeMOdel,children}) {

  function closeModal() {
     closeMOdel(false)
  }
  return (
    <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
         <span onClick={closeModal} style={{fontWeight:"600",fontSize:"24px",cursor:"pointer"}}>X</span>
        
        <div>
        {
           children
        }

        </div>
       
      </Modal>
  )
}
