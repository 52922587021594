
import AnimatedBox from '../../components/AnimatedBox'
import { Helmet } from 'react-helmet'
import React,{useState} from 'react'
import Shorts from '../../components/Shorts';
import Videos from '../../components/Videos';

export default function OdrTv() {
     const [section,setSection] = useState(0)

  function translatehighlight(endpoint){
    const value = {
      0 : "0%",
      1:"100%",
    }
   const highlight = document.getElementById('hightv')
     highlight.style.transform =`translateX(${value[endpoint]})`
 }
function changeStep(node){
    const allElement = document.querySelectorAll('.odrtvnav li')
         for(let i=0;i<allElement.length;i++ ){
    
              if(i === node){
                  allElement[i].classList.add('active')
                  translatehighlight(node)
                   setSection(node)
              }
              else {
                 allElement[i].classList.remove('active')
              }
         }
}
  return (
    <div className='odrTvpage'>
       <Helmet>
  <title>Explore Presolv360: Engaging Youtube Shorts and Videos</title>
<meta name="description" content="Discover captivating content on Presolv360's YouTube channel. Dive into short clips and videos for insights, entertainment, and more. Subscribe now!" />
<meta name="keywords" content="odr tv" />
<link rel="canonical" href="https://presolv360.com/odr-tv" />
  </Helmet>
       <section className='landing_wrap firstBreak'>
       {
        window.innerWidth > 600 ?   <h1 style={{padding:"2em"}}>
    Explore bite-sized snippets and interesting videos covering diverse topics for a comprehensive understanding of online dispute resolution
        </h1> :   <h1 style={{padding:"0 .5em",fontSize:"32px"}}>
        Explore bite-sized snippets and interesting videos covering diverse topics for a comprehensive understanding of online dispute resolution
        </h1>
       }
      
         {/* <h3><span style={{color:"var(--color_2)"}}>#SettleTheBattle</span></h3> */}
         <AnimatedBox/>
    </section>
    <div style={{display:"grid",placeItems:"center",width:"100%",padding:"2em"}}>
    <ul className='odrtvnav'>
              <span className='highlight_tv' id='hightv'></span>
            <li className='active'  onClick={()=>{changeStep(0)}}>
           Shorts
            </li>
            <li onClick={()=>{changeStep(1)}}>
            Videos
                </li>
           </ul>
    </div>
    {
            (()=>{
                switch (section) {
                    case 0:
                          return (
                             <Shorts/>
                          )
                              
                         case 1 : 
                         return (
                           <Videos/>
                          )
                  
                    default:
                        break;
                }
            })
          () }
   
    </div>
  )
}
