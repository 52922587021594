import Navbar from "./Section/navbar/Navbar";
import {Route,Routes } from "react-router-dom";
import Career from "./pages/Career";
import ResourceCenter from "./pages/resourceCenter";
import Home from "./pages/Home";
import '../src/assets/styles/Resource.css'
import About from "./pages/AboutPage";
import Footer from "./Section/Footer";
import './assets/styles/AboutPage.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Negotiation from "./pages/OurOffering/Negotiation";
import Mediation from "./pages/OurOffering/Mediation";
import Arbitration from "./pages/OurOffering/Arbitration";
import ScrollToTop from "./components/ScrollToTop";
import Podcast from "./pages/Resources/Podcast";
import OdrTv from "./pages/Resources/OdrTv";
import GlobalSpeaking from "./pages/Resources/GlobalSpeaking";
import CaseAlert from "./pages/Resources/CaseAlert";
import ResearchPaper from "./pages/Resources/ResearchPaper";
import Standard from "./pages/ExtraPages/Standard";
import CookiesPolicy from "./pages/ExtraPages/CookiesPolicy";
import PrivacyPolicy from "./pages/ExtraPages/PrivacyPolicy";
import TermsCondition from "./pages/ExtraPages/TermsCondition";
import RulesCode from "./pages/ExtraPages/RulesCode";
import FAQ from "./pages/ExtraPages/FAQ";
import Clauses from "./pages/Clauses";
import Chat360 from "./pages/Resources/Chat360";
import Cookie from "./components/Cookie";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import OpEd from "./pages/Resources/Op_ed";
function App() {
   useEffect(()=>{
    AOS.init();
   })
  return (
    <>  
    <div className="main">  
    <Cookie/>
      <Navbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/impression" element={<ResourceCenter />} />
        <Route path="/solutions/negotiation" element={<Negotiation />} />
        <Route path="/solutions/mediation" element={<Mediation />} />
        <Route path="/solutions/arbitration" element={<Arbitration />} />
        <Route path="/odr-on-air" element={<Podcast />} />
        <Route path="/odr-tv" element={<OdrTv/>} />
        <Route path="/globally-speaking" element={<GlobalSpeaking/>} />
        <Route path="/about-us" element={<About />} />
        <Route path="/case-alert" element={<CaseAlert/>} />
        <Route path="/research-paper" element={<ResearchPaper/>} />
        <Route path="/odr-standard" element={<Standard/>} />
        <Route path="/cookie-policy" element={<CookiesPolicy/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-conditions" element={<TermsCondition/>} />
        <Route path="/fees" element={<RulesCode/>} />
        <Route path="/faqs" element={<FAQ/>} />
        <Route path="/clause" element={<Clauses/>} />
        <Route path="/chat360" element={<Chat360/>} />
        <Route path="/op-ed" element={<OpEd/>} />
      </Routes>
      <Footer/>
    </div>
    </>
  );
}

export default App;
