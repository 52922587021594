import React from 'react'
import Image1 from '../../../assets/Images/oped/page2/Oped2 1.png'
import Image2 from '../../../assets/Images/oped/page2/Oped4 1.png'
import Image3 from '../../../assets/Images/oped/page2/Oped5 1.png'
import Image4 from '../../../assets/Images/oped/page2/Oped1 1.png'
import Image5 from '../../../assets/Images/oped/page2/Oped14 1.png'
import Image6 from '../../../assets/Images/oped/page2/Oped3 1.png'
import Image7 from '../../../assets/Images/oped/page2/Oped6 1.png'
import Image8 from '../../../assets/Images/oped/page2/Oped7 1.png'
import Image9 from '../../../assets/Images/oped/page2/Oped8 1.png'
import Image10 from '../../../assets/Images/oped/page2/Oped15 1.png'
import Image11 from '../../../assets/Images/oped/page2/image 13.png'
export default function Page2() {
  return (
    <div className='pageBody'>
    <div className='oped_leftSection'>
         <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/a-digital-legal-system-needed-for-speedy-justice/article66410182.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image1} alt="oped_1" />
    <h3>A digital legal system needed for speedy justice</h3>
         </a>
         <a  rel="noreferrer"  href="https://www.thehindu.com/opinion/op-ed/addressing-the-challenges-in-new-age-digital-commerce/article65732819.ece?homepage=true" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image2} alt="oped_2" />
    <h3>Addressing the challenges in new-age digital commerce</h3>
         </a>
         <a  rel="noreferrer"  href="https://www.scconline.com/blog/post/2021/03/24/mediation-2/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image3} alt="oped_3" />
    <h3>Mandatory pre-institution mediation – Purpose v. Procedure</h3>
         </a>
         <a  rel="noreferrer"  href="https://www.livemint.com/opinion/online-views/opinion-collections-and-dispute-resolution-hope-faith-and-more-for-lenders-11599974221412.html" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image4} alt="oped_4" />
    <h3>Opinion | Collections and dispute resolution - hope, faith and more for lenders
</h3>
         </a>
         <a  rel="noreferrer"  href="https://bwlegalworld.businessworld.in/article/Decoding-The-Digital-Revolution-In-India-s-Courtrooms/13-12-2023-501925/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image5} alt="oped_5" />
    <h3>Decoding The Digital Revolution In India’s Courtrooms
</h3>
         </a>
    </div>
    <div>
    <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/how-to-tackle-delayed-payments-to-msmes/article65802608.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image6} alt="oped_5" />
    <h3>How to tackle delayed payments to MSMEs</h3>
         </a>
         <a  rel="noreferrer"  href="https://www.livemint.com/opinion/online-views/opinion-can-e-integrated-ombudsman-scheme-improve-consumer-friendliness-11612790010828.html" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image7} alt="oped_6" />
    <h3>Opinion | Can e-integrated ombudsman scheme improve consumer friendliness?</h3>
   
         </a>
      <div className='oped_flex'data-aos="fade-up" data-aos-duration="1500">
      <a  rel="noreferrer"  href="https://thedailyguardian.com/insurance-companies-is-the-time-ripe-for-an-online-dispute-resolution-policy/" target="_blank" className='card_oped'>
  <img src={Image8} alt="oped_3" />
    <h3>Insurance companies: Is the time ripe for an Online Dispute Resolution policy?</h3>           </a>
<a  rel="noreferrer"  href="https://www.policybazaar.com/pblife/whats_new/Online_Dispute_Resolution_An_Idea_Whose_Time_Has_Come" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image9} alt="oped_7" />
    <h3>Online Dispute Resolution: An Idea Whose Time Has Come</h3>         </a>
      </div>
      <div className='oped_flex'data-aos="fade-up" data-aos-duration="1500">
      <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/digital-dispute-resolution/article67727832.ece" target="_blank" className='card_oped'>
  <img src={Image10} alt="oped_10" />
    <h3>Digital dispute resolution </h3>          </a>
<a  rel="noreferrer"  href="https://bwlegalworld.businessworld.in/article/Legally-Ever-After-The-Legal-Landscape-For-Modern-Indian-Family/16-10-2023-494788/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
  <img src={Image11} alt="oped_11" />
    <h3>Legally Ever After: The Legal Landscape For Modern Indian Family</h3>         </a>
      </div>
    </div>
  </div>
  )
}
