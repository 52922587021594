import React from 'react'

export default function Case3() {
  return (
    <>
     <b style={{textAlign:"center"}}>
     Supreme Court of India Refers Issue of Enforceability of Unstamped Arbitration Agreements to Seven-Judge Bench
</b>
<div>
   <i>Case Title: Bhaskar Raju and Brothers and Anr V. s Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram & Other Charities and Ors

   
   </i>
   <i>
   Court: Supreme Court of India

   
   </i>
   <i>Date of Order: 26.09.2023 </i>
   </div>
    <p style={{paddingTop:"0em"}} >
    The Supreme Court of India has referred the question of whether unstamped or insufficiently stamped arbitration agreements are enforceable to a seven-judge bench. This decision was made by a five-judge bench led by the Chief Justice of India, DY Chandrachud while hearing a curative petition in the case of Bhaskar Raju and Brothers and Anr V. s Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram & Other Charities and Ors. The ruling had held that an arbitration clause in an insufficiently stamped agreement could not be acted upon by the court.
   </p>  

   
   
   <p style={{paddingBottom:"1.5em"}}>
   The reference was made in the hearing when the recently delivered judgment of the Hon’ble Supreme Court in the case of M/s. N.N. Global Mercantile Pvt. Ltd. v. M/s. Indo Unique Flame Ltd. And Ors
came for consideration. The Hon’ble Supreme Court bench emphasized that the issue is of significant importance and has created “limitless uncertainty” in the field of Arbitration law, and hence decided to refer the issue to a larger bench.
   </p>
   </>
  )
}
