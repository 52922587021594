import React from 'react'

export default function Case11() {
  return (
    <>
    <p style={{paddingTop:"1.5em"}} >
    <b>Challenge: Non-executable arbitral awards due to unilateral arbitrator appointments 
</b>
</p> 
<p><b>Solution: Institutional Arbitration through Online Dispute Resolution (ODR) Platform</b></p> 
<p>The Supreme Court of India dismissed a Special Leave Petition filed against the Delhi High Court’s decision, which upheld a Commercial Court's refusal to execute an award passed by a unilaterally appointed arbitrator.
</p>
   <div>
   <i>
Case Title: Kotak Mahindra Bank Ltd. vs Narendra Kumar Prajapat

  -
   </i>
   <i>
   Court: Supreme Court of India
   </i>
   <i>Date of Judgement:  12.12.2023</i>
   </div>
   <p>
The Hon’ble Supreme Court noted the ineligibility of the arbitrator appointed unilaterally by the petitioner as per Section 12(5) of the Arbitration and Conciliation Act, 1996 (“Act”) and thereby found no grounds for interference under Article 136 of the Constitution of India, leading to the dismissal of the petition.
   </p>
   <p>
   This decision aligns with the precedents set by the Supreme Court in cases like TRF Ltd., Perkins Eastman, and Bharat Broadband. These cases have established that unilateral appointments of sole arbitrators are void ab initio, non-est, and a nullity.
   </p>
     <p>
        <b>Challenge</b><br></br>
        When a dispute arises in lending agreements, it is generally the lender who initiates the arbitration process. Essentially, the lender has two options in view of the aforesaid rulings:

     </p>
     <p>
     i. Appointment of an arbitrator by mutual consent: This option is not pragmatic as the borrower would, generally, not want to partake in adversarial proceedings.
<br>
</br>
ii. Application to the concerned court for appointing the arbitrator: While considering the low value of lending disputes, this alternative, is neither economical nor efficient.<br></br>
Consequently, lenders are often compelled to appoint arbitrators unilaterally.


     </p>
     <p>
        <b>Solution</b><br></br>
        The judiciary and the legislature have endorsed the concept of institutional arbitration. Further, a technology-driven solution housed under the aegis of an institution can address the concerns relating to unilateral appointment by ensuring that both parties have an equal say in the appointment of the arbitrator. Not only is party autonomy upheld, but issues of independence and impartiality of the arbitrator are also addressed while ensuring speed and affordability of the arbitration proceedings. This approach ensures a fair appointment process and significantly unburdens the courts.
     </p>
     <p>
     <b>Reasoning</b><br></br>
     Reference to an independent and neutral ODR institution that administers arbitration by leveraging its institutional structure is the solution to overcome the challenge of unilateral appointment of arbitrators. The institution’s role is limited to providing administrative support to the parties and the arbitrator for conducting the proceedings virtually and has no interest in the outcome of the dispute or any conflict of interest. The institution plays no role whatsoever in the determination of any dispute and does not undertake any adjudicatory functions but only provides a virtual platform along with all support services.
     </p>
     <p>
     The institution empanels independent, qualified arbitrators with the required competence, knowledge and varied expertise on its panel of arbitrators. The panel is a broad-based panel that consisting of retired judges and other professionals like lawyers, engineers, accountants, etc. An arbitrator is assigned from the said panel on behalf of all the parties to preside over the arbitration proceedings. The panel cannot be hand-picked by any one party and it is ensured that any person on the panel is not connected with any of the parties to the matter. For this purpose, the institution can utilize an automated case allocation tool that runs algorithms to randomize the process of assignment. 
     </p>
     <p>
     The arbitrator is required to provide acceptance and consent to act as an arbitrator in the dispute, confirm that he/she is qualified, possesses the required competence, knowledge and expertise to deal with the subject matter of the dispute and undertakes to devote sufficient time to conduct the arbitration proceedings within the time limits prescribed. Further, the arbitrator makes requisite disclosures prescribed under Section 12 read with the Fifth Schedule to the Act. The arbitrator further confirms that his / her relationship with the parties or counsel or the subject matter of this dispute does not fall under any of the categories specified in the Seventh Schedule to the Act.

     </p>
     <p>
     From the inception, the parties are intimated of the administration of the proceedings through the ODR institution. Thereafter, in the notice of arbitration, details of the arbitrator along with the option to seek the list of arbitrators on the panel for the purpose of choosing another arbitrator to resolve the dispute is provided to the parties. Further, the parties are also provided with an option to hold a preliminary conference with the arbitrator in addition to a simplified procedure to challenge the assignment of the matter to the arbitrator, as per law. Accordingly, all the parties to the dispute have an equal say in the appointment of the arbitrator, the constitution of the arbitral tribunal and also have the option to challenge the arbitrator on grounds concerning the arbitrator’s independence or impartiality or qualifications.

     </p>
      <p>
      Such a scenario would not be categorized as unilateral appointment of the arbitrator. The parties are made aware of the assignment of the arbitrator at the same time and have equal say and opportunity with aspects concerning the appointment of the arbitrator and the constitution of the arbitral tribunal, thus, eliminating any influence of either party in the appointment of the arbitrator.

      </p>

      <p>
        <b>Judicial backing
</b><br></br>
Endorsing this viewpoint, the Commercial Court, Bengaluru , while deciding an application under Section 14(2) read with section 14(1)(a) of the Act, where the arbitration proceedings were administered by Presolv360, held that:

      </p>
      <p>
      “The applicant though has sought for termination of the arbitrator's mandate on the ground of ineligibility, none of the circumstances as specified in the Seventh Schedule are shown to have been existing. Therefore, the sole arbitrator appointed through ODR platform, per se, cannot be held to be ineligible under Section 12(5) of the Act.” [Emphasis supplied]

      </p>

 
   <p style={{paddingBottom:"1.5em"}}>
    <b>Conclusion</b><br></br>
   Leveraging the institutional structure of ODR institutions, parties can overcome the challenges pertaining to unilateral appointment of arbitrators leading to non-executability of the arbitral award or rendering the arbitration proceedings non-est and void ab initio.
   </p>
   </>
  )
}
