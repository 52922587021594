import React from 'react'
import { Helmet } from 'react-helmet'
import AnimatedBox from '../../components/AnimatedBox'
import Target from '../../assets/Images/Cookies/target.png' 
import Functionality from '../../assets/Images/Cookies/Functionality.png' 
import Performance from '../../assets/Images/Cookies/Performance.png' 
import Strictly from '../../assets/Images/Cookies/Strictly.png' 
export default function CookiesPolicy() {
  return (
    <div>
    <Helmet>
    <title>Presolv360 | Cookie Policy | Legaltech | ODR India</title>
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
<meta name="msapplication-TileImage" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />

<meta name="description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />

<meta name="keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />

<meta property="og:keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<meta property="og:description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />
<meta property="og:image" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:image:secure_url" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:url" content="https://www.presolv360.com"/>
<meta property="og:title" content=" Presolv360 | Dispute resolution made easy" />
<meta property="og:type" content="article" />
    </Helmet>
    <div className='firstBreak extraPage'>
    <section className='landing_wrap'>
      {
        window.innerWidth > 600 ?<h1 style={{padding:"0 1.5em"}}>
  The Cookies Notice explains how Presolv360 uses cookies and similar technologies in the course of our business
        </h1> : <h1 style={{width:"100%"}}>
        The Cookies Notice explains how Presolv360 uses cookies and similar technologies in the course of our business
        </h1>
      }
          <AnimatedBox/>
      </section>
    </div>
   <div className='extra_section_wrap cookies'>
    <h3>What is a Cookie ?</h3>
    <p>Cookies are small text files that a website asks the user’s browser to save on the user’s system/device, when a user visits the website, in order to remember the user’s device upon revisiting the website or during the session. Cookies set up by the domain <a href="https://presolv360.com/" target="_blank" rel="noreferrer">Presolv360</a>, are called first-party cookies and cookies set by other domain(s) than the website(s) you are visiting are termed as third-party cookies. Third-party cookies are generally used for marketing and advertising purposes.</p>

<h3>Purpose of using cookies ?</h3>
 <p>Our website <a href="https://presolv360.com/" target="_blank" rel="noreferrer">Presolv360</a> uses cookies and other tracking technologies for the following purposes :</p>
    <ul>
        <li>To analyze your use of our website
</li>
        <li>For promotional and marketing purposes
</li>
        <li>To understand your preferences such as preferred language
</li>
        <li>To deliver information that matches your interests
</li>
        <li>To help us understand our audience and traffic patterns
</li>
        <li>To let you automatically log into programs and parts of our site that require membership
</li>
        <li>To manage and present the information displayed on our website
</li>
    </ul>
    <h3>Types of cookies used by Presolv360</h3>
    <ul>
        <li>Strictly Necessary Cookies
</li>
        <li>Targeting Cookies
</li>
        <li>Functionality Cookies
</li>
        <li>Performance Cookies
</li>
    </ul>
    <h3>Cookie List</h3>
    <p>
The table below provides additional details on the specific cookies used on <a href="https://presolv360.com/" target="_blank" rel="noreferrer">Presolv360</a></p>
    <li className="boldBullet">Strictly Necessary Cookies </li>
    <p>Some cookies are required for technical reasons in order for our websites to support functions such as logging in, and payment transactions.</p>
  <img src={Strictly} alt='Strictly Necessary Cookies'/>
   
   <li className="boldBullet">Targeted Cookies </li>
   <p>These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store direct personal information but are based on uniquely identifying your browser and internet device.</p>
   <img src={Target} alt='Targeted Cookies'/>

   <li className="boldBullet">Functionality Cookies </li>
   <p>Some cookies are required for technical reasons in order for our websites to support functions such as logging in, and payment transactions.</p>
   <img src={Functionality} alt='Functionality Cookies'/>

   <li className="boldBullet">Performance Cookies  </li>
   <p>Performance cookies are cookies used specifically for gathering data on how visitors use a website, which pages of a website are visited most often, or if they get error messages on web pages. These cookies monitor only the performance of the site as the user interacts with it. These cookies don’t collect identifiable information on visitors, which means all the data collected is anonymous and only used to improve the functionality of a website.</p>
   <img src={Performance} alt='Performance Cookies'/>

   <p>Any cookie, whose purpose has not been identified by us or is unknown to our current understanding, is classified together as “Unclassified Cookies”. Such cookies may or may not adhere to our policy and we cannot take ownership of said cookies, however, we would periodically and actively try to investigate them and categorize them in their respective cookie categories for the benefit of our users, partners and customers.</p>
    
    <h3>Other tracking technologies like web beacons</h3>
    <p>Cookies are not the only way to recognize or track visitors on a website. We may use other similar technologies from time to time, like web beacons (sometimes called “tracking pixels” or “clear gifs”). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our websites or opened an e-mail including these. In many instances, these technologies are reliant on cookies to function properly, and hence, declining cookies will impair their functioning.</p>
  
  <h3>How can I control the cookie preferences?</h3>
  <p>Most browsers are set to accept cookies by default. However, you may remove or reject cookies in your browser’s settings. Removing or rejecting the cookies may affect the performance of the website. You may opt-out of the DoubleClick cookie by visiting the Google advertising opt-out page: <a href="http://www.google.com/policies/privacy/ads/" target="_blank" rel="noopener noreferrer">http://www.google.com/policies/privacy/ads/</a>. Or you may opt-out of Google Analytics by visiting the Google Analytics Opt-out page: <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout</a>.</p>
   

   <h3>Update and Review</h3>
   <p>We may update this Cookie Policy from time to time, therefore, we encourage you to periodically review this page for the latest information on the Policy.
</p>
   </div>

    </div>
  )
}
