import React from 'react'

export default function Case12() {
  return (
    <>
       <b>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
   </b>
   <div>
   <i>Case Title:  ODR-administered Arbitration finds Acceptance in Execution Proceedings Despite Unilateral Arbitrator Appointment Clause in the Agreement
  
   </i>
   <i>
   Court: Commercial Court, New Delhi
   </i>
   <i>Date of Judgement:  05.01.2024</i>
   </div>
    <p style={{paddingTop:"1.5em"}} >
    The Commercial Court in Saket, New Delhi, on 5th January 2024, dealt with an execution petition based on an arbitration award in a dispute administered by Presolv360 ODR Institution. The pivotal issue was the unilateral arbitrator appointment clause in the agreement. The Court was intimated that the appointment of an arbitrator was not unilateral. Notice had been issued to the opposite party for the appointment of an arbitrator from a neutral institution i.e. Presolv360, which is a recognized independent ODR platform, and no objection was raised. The Court stated that the appointment of the Arbitrator was not unilateral and issued notice to the judgment debtor in the execution proceedings. 
</p>  


 
   </>
  )
}
