import React from 'react'

export default function Case13() {
  return (
    <>
    <b  style={{textAlign:"center"}}>Providing Wrong Address Bars Objection to Arbitration Notice  
   </b>
   <div>
   <i>Case Title:  Kotak Mahindra Bank Ltd. vs Narendra Kumar Prajapat
   </i>
   <i>
   Court: Supreme Court of India
   </i>
   <i>Date of Judgement:  12.12.2023</i>
   </div>
    <p style={{paddingTop:"1em"}} >
    In a notable decision, the Hon’ble Delhi High Court addressed a jurisdictional issue involving the appointment of an arbitrator in a dispute arising from a partnership deed. The respondent objected to the petitioner's application under Section 11 of the Arbitration and Conciliation Act, 1996, (“Act”) contending that the invocation notice under Section 21 of the Act was not served at the correct address, rendering the petition premature.

</p>  
<p style={{paddingTop:"1em"}}>
The Hon’ble High Court rejected the respondent’s objection, observing that the respondent had himself provided the same address in a separate proceeding. The court clarified that the purpose of an invocation notice is to inform the opposite party about the claimant’s intent to invoke arbitration, and since the respondent was aware of the petitioner’s intention to pursue arbitration since 2021, the petition could not be deemed premature.

</p>
<p style={{paddingTop:"1em"}}>
Distinguishing a cited case where the respondent was unaware of the invocation notice due to an incorrect address, the Hon’ble High Court upheld the validity of the invocation notice and appointed an arbitrator to adjudicate the disputes arising from the partnership deed.
</p>
   
  
 
   {/* <p style={{paddingBottom:"1.5em"}}>
   The case involved a dishonoured cheque, initially part of an arbitration agreement in a Memorandum of Understanding (MoU) between the parties. The court determined that the nature of the cheque (whether it was security or not) is a matter for trial, thereby allowing the Section 138 complaint to proceed despite the arbitration.
   </p> */}
   </>
  )
}