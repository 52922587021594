 
import axios from "axios";

const Bussiness = (data) => {
    var formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("orgname", data.orgname);
    formdata.append("email", data.email);
    formdata.append("phone",data.phone);
    return axios.post("https://script.google.com/macros/s/AKfycbxQJr0U5cn1fFAcU2W6-aEzW7xthyN514xN33aRRVkMm3A3uXXAP0A6nneKxu8WOw2tPA/exec", formdata)
}

const NewsLetter = (email) => {
    var formdata = new FormData();
    formdata.append("email", email);
    return axios.post("https://script.google.com/macros/s/AKfycbyoqAdgvP42AQJk5o9IUhsL63FaDEAqwebGeuB_vk71DylD82PEq9BGVO0nYA3oS346IA/exec", formdata)
}

export {Bussiness,NewsLetter}