import React from 'react'
import { Helmet } from 'react-helmet'
import AnimatedBox from '../../components/AnimatedBox'
import Image2 from '../../assets/Images/Global_speaking.png'
import Image3 from '../../assets/Images/Global_speaking_2.jpg'
export default function GlobalSpeaking() {
  return (
    <div>
          <Helmet>
  <title>ADR & ODR: The Future of Dispute Resolution</title>
<meta name="description" content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era." />
<meta name="keywords" content="dispute resolution global" />
  </Helmet>
  <div className='firstBreak'>
  <section className='landing_wrap'>

    {
       window.innerWidth > 600 ?  <h1>
       Explore insights from luminaries around the world in the field of alternative and online dispute resolution
       </h1> : <h1>
        Explore insights from luminaries around the world in the field of alternative and online dispute resolution
        </h1>
    }
        <AnimatedBox/>
    </section>
  </div>
 
  <div className='globalSpeaking_page_wrap'>
  <div className='resource_wrap'>
        <div className='course_right_wrap'>
        <img src={Image2} alt='Blog_image'/>
       </div>
       <div className='resource_left_wrap'>
         <h3>An Interview with Rahim Shamji, Founder, ADR ODR International
</h3> 
<p style={{lineHeight: "140%",
padding:"0 1em",
  textAlign: "justify",
  letterSpacing: "1px"}}>Explore the future of dispute resolution in a revealing interview with Rahim Shamji, Founder of ADR ODR International. Gain insights on the impact of ADR, the relevance of ODR, and advice for legal professionals navigating the changing landscape.</p>
         <a rel="noreferrer" href='https://presolv360.com/resources/globally-speaking-interview-rahim-shamji-founder-adr-odr-international/' target='_blank' className='orange_btn' style={{minWidth:"max-content"}}>Learn More</a>
       </div>
        </div>
        <div className='resource_wrap'>
        <div className='course_right_wrap'>
        <img src={Image3} alt='Blog_image'/>
       </div>
       <div className='resource_left_wrap'>
         <h3>An Interview with Mr. Tariq Khan, Registrar, International Arbitration and Mediation Centre, Hyderabad
</h3> 
<p style={{lineHeight: "140%",
padding:"0 1em",
  textAlign: "justify",
  letterSpacing: "1px"}}>Explore the future of arbitration in a revealing interview with Mr. Tariq,  A seasoned dispute resolution practitioner with extensive experience in international arbitration practices. Gain insights on the international arbitration.</p>
         <a rel="noreferrer" href='https://presolv360.com/resources/i-did-not-choose-law-law-chose-me/' target='_blank' className='orange_btn' style={{minWidth:"max-content"}}>Learn More</a>
       </div>
        </div>
  </div>
 
      </div>
  )
}
