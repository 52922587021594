import React, { useState } from 'react'
import AnimatedBox from '../../components/AnimatedBox'
import { Helmet } from 'react-helmet'

import Page1 from './opedPages/Page1'
import Page2 from './opedPages/Page2'
import Page3 from './opedPages/Page3'
export default function Op_ed() {
   const [currentPage,setCurrentPage] = useState(1)
    const pageNumbers = [1,2,3]
  return (
    <div className='opedPage'>
           <Helmet>
  <title>Explore Presolv360: Engaging Youtube Shorts and Videos</title>
<meta name="description" content="Discover captivating content on Presolv360's YouTube channel. Dive into short clips and videos for insights, entertainment, and more. Subscribe now!" />
<meta name="keywords" content="op ed" />
<link rel="canonical" href="https://presolv360.com/op-ed" />
  </Helmet>
       <section className='landing_wrap firstBreak'>
       {
        window.innerWidth > 600 ?   <h1 style={{padding:"2em"}}>
            Explore diverse perspectives and thought-provoking ideas in the space of online dispute resolution in our Op-ed section
        </h1> :   <h1 style={{padding:"0 .5em",fontSize:"32px"}}>
        Explore diverse perspectives and thought-provoking ideas in the space of online dispute resolution in our Op-ed section       </h1>
       }
      
         {/* <h3><span style={{color:"var(--color_2)"}}>#SettleTheBattle</span></h3> */}
         <AnimatedBox/>
    </section>
    
    {
            (()=>{
                switch (currentPage) {
                    case 1:
                          return (
                             <Page1/>
                          )
                              
                         case 2 : 
                         return (
                           <Page2/>
                          )
                          case 3 : 
                          return (
                            <Page3/>
                           )
                    default:
                        break;
                }
            })
          () }
    <ul className='pagination'>
                {pageNumbers.map(pgNumber => (
                    <li style={{cursor:"pointer"}} onClick={() => {
                        setCurrentPage(pgNumber)
                     window.scrollTo(0,0)  
                }}   key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >

                        <a 
                            className='page-link' 
                           >
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}

            </ul>
    </div>
  )
}
