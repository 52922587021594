import React from 'react'

export default function Case7() {
  return (
    <>
      <b>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
   </b>
    
   <div>
   <i>Case Title:  Newton Engineering and Chemicals Limited and Ors v. Uem India Pvt Ltd
  
   </i>
   <i>
   Court:  Delhi High Court 
   </i>
   <i>Date of Judgement: 23.11.2023</i>
   </div>
    <p style={{paddingTop:"1.5em"}} >
    The Hon’ble Delhi High Court ruled that arbitration and Section 138 proceedings arise from separate causes of action and can coexist. This decision was influenced by the Supreme Court's ruling in Sri Krishna Agencies v. State of A.P. & Anr. affirming the simultaneous continuance of criminal and civil proceedings for separate causes.
</p>  

 
   <p style={{paddingBottom:"1.5em"}}>
   The case involved a dishonoured cheque, initially part of an arbitration agreement in a Memorandum of Understanding (MoU) between the parties. The court determined that the nature of the cheque (whether it was security or not) is a matter for trial, thereby allowing the Section 138 complaint to proceed despite the arbitration.
   </p>
   </>
  )
}
