import React,{useState} from 'react'
import Pagination from './Pagination';

export default function Shorts() {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const shortsData = [
      {
        tittle:"Why should companies adopt ODR as the first choice for dispute resolution?",
        description:"Ms. Komal Gupta, Chief Innovation Officer at CAM, delves into the merits of Online Dispute Resolution (ODR). She emphasizes why ODR should be the preferred method for resolving disputes, highlighting its speed, convenience, and efficiency.",
        videoLink:"https://www.youtube.com/watch?v=K5Bsr_ultJs"
        },

        {
          tittle:"Virtual execution of EPs – Justice Pradeep Nandrajog",
          description:"Justice Pradeep Nandrajog, Chief Justice (Retd.), Bombay High Court and Aman Sanghavi, Cofounder, Presolv360 talk about the virtual execution of EPs – whether they can be e-signed, e-stamped, and electronically generated.",
          videoLink:"https://www.youtube.com/watch?v=AYBd3X-eC5U"
          },

          {
            tittle:"Importance of Institutions like Presolv360 (context of Perkins-Eastman): Justice Pradeep Nandrajog",
            description:"Justice Pradeep Nandrajog, Chief Justice (Retd.), Bombay High Court discusses the importance of institutions like Presolv360 and the role played by them in the context of the Perkins-Eastman judgement.",
            videoLink:"https://www.youtube.com/watch?v=XWwBGdZdKt4"
            },

        {
          tittle:"Adoption of ODR in legal departments of corporate entities – Sarath Mohan, HDFC Bank",
          description:"Mr Sarath Mohan, Head – Legal (Retail), HDFC Bank talks about his vision for the adoption of online dispute resolution (ODR) by legal departments of corporate entities like banks.",
          videoLink:"https://www.youtube.com/watch?v=3lOlWWyn3nM"
          },
          {
            tittle:"The Way Forward for Arbitration and Mediation – Sarath Mohan, HDFC Bank",
            description:"In conversation with Aman Sanghavi, Cofounder, Presolv360, Mr Sarath Mohan, Head – Legal (Retail), HDFC Bank talks about the impact of arbitration and mediation and the way forward for banks.",
            videoLink:"https://www.youtube.com/watch?v=bSIoG9Uegwo"
            },
            
          {
            tittle:"How to fast-track enforcement of settlement agreements?",
            description:"Ms. Chitra Narayan (Lawyer, Author and Mediator) discusses the fast-track enforcement of settlement agreements in the mediation law.",
            videoLink:"https://www.youtube.com/watch?v=GZP9LaD8TLo"
            },
            {
              tittle:"What happens if parties retract from a signed mediation agreement?",
              description:"Mr. A J Jawad (Accredited Mediator and Trainer) discusses a scenario in which parties retract from a signed mediation agreement.",
              videoLink:"https://www.youtube.com/watch?v=OEMDohf7snE"
              
              },
   
              {
                tittle:"How does the mediation act help in solving complicated commercial disputes?",
                description:"Mr. Sagar Kulkarni (Advocate, Mediator and Arbitrator) discusses the role of the mediation act in solving commercial disputes.",
                videoLink:"https://www.youtube.com/watch?v=3AkFe71HMHc"
                
                } ,     
                {
                  tittle:"Benefits of Online Mediation through a MSP like Presolv360",
                  description:"Justice Kannan Krishnamoorthy (Former Judge, Punjab & Haryana High Court) talks about the benefits of online mediation with the help of MSP like Presolv360. ",
                  videoLink:"https://www.youtube.com/watch?v=x3rcShZkdlQ"
                  
                  } ,  {
                    tittle:"How Can Organizations leverage mediation to resolve disputes?",
                    description:"Ms. Chitra Narayan (Lawyer, Author and Mediator) discusses how organizations can leverage mediation to resolve their disputes.",
                    videoLink:"https://www.youtube.com/watch?v=fakzx9IShP8"
                    
                    },
  {
    tittle:"Increasing Access to Justice Through ODR and AI-based Language Translation",
    description:"AI-based language translation offers a solution to breaking barriers in linguistically diverse India. Effective use of this technology also offers the chance to improve access to justice for India's masses, through integration with Online Dispute Resolution (ODR) services.",
    videoLink:"https://www.youtube.com/watch?v=wKYG6u6QR8g"
    
  },  
  {
    tittle:"Project Sahyog (Presolv360)",
    description:"Presolv360 has launched an initiative to solve the issue of delayed payments in the MSME sector. We have designed and developed a set of resources to help MSMEs right from Udyam Registration to ultimate recovery of dues.",
    videoLink:"https://www.youtube.com/watch?v=pXA37aOAeF0"
    
},
  {
    tittle:"BW Businessworld Festival of Fintech Conclave and Awards 2021",
    description:"It gives us immense pleasure to share with our Presolv community that we have been felicitated with the 'Best Start-up' award under the process innovation in #fintech category at the BW Businessworld Festival of Fintech Conclave and Awards 2021!",
    videoLink:"https://www.youtube.com/watch?v=rHRNn91qKGM"
    
},
{
    tittle:"#SettleTheBattle",
    description:"The journey from peace to conflict is very small, but the journey from conflict to resolution is never-ending. ",
    videoLink:"https://www.youtube.com/watch?v=6A5cQHAl46w"
    
}

     ]
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = shortsData.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(shortsData.length / recordsPerPage)
  return (
    <section className='poadcast_main_wrap'>

    {
        currentRecords.map((poadcast)=>{
            return (
                <div className='resource_wrap'>
                <div className='course_right_wrap'>
                <img className='thumbnail' src={`http://i3.ytimg.com/vi/${poadcast.videoLink.split('=').slice(-1).toString()}/hqdefault.jpg`}  style={{width:"85%"}} alt='shortsimage'/>
               </div>
               <div className='resource_left_wrap'>
                 <h3>{poadcast.tittle}
        </h3> 
        <p style={{lineHeight:"140%"}} >{poadcast.description}
        </p>
        <div className='resourse_social_media_wrap'>
            <a className='orange_btn' target='_blank' rel="noreferrer" href={poadcast.videoLink}>Watch</a>
        </div>
               </div>
                </div>
            )
        })
    }
    <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
    />
    </section>
  )
}

