import React, { useEffect, useState } from 'react'

export default function Cookie() {
        const [cookies,setcookies] = useState(false)
    function setCookie() {
            let date = new Date();
            date.setTime(date.getTime() + (1 * 6 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = "_presolv_visit=1; "+ expires + "; path=/";
              document.getElementById('cookie').style.display = "none"
    }
     useEffect(()=>{
      if(document.cookie.indexOf('_presolv_visit') !== -1 ) {
        setcookies(true)
     }
     },[cookies])
    
  return (

        !cookies &&   <div className='cookies_wrap' id='cookie' >
        <p>This website stores cookies on your computer. By using our website you consent to our use of cookies as described in our <a href='/cookie-policy' target='_blank' >Cookie Policy</a> .</p>
        <button className='blue_btn acceptCookie'  onClick={()=>{setCookie()}}>Accept</button>  
      </div>
    
  )
}
