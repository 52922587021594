import React from 'react'
import Marqueee from '../components/marquee'
import Image1 from '../assets/Images/Media&awards/Business_today-removebg-preview.png'
import Image2 from '../assets/Images/Media&awards/Legal_World-removebg-preview 1.png'
import Image3 from '../assets/Images/Media&awards/Media1.png'
import Image4 from '../assets/Images/Media&awards/Media10.png'
import Image5 from '../assets/Images/Media&awards/Media12.png'
import Image6 from '../assets/Images/Media&awards/Media2.png'
import Image7 from '../assets/Images/Media&awards/Media3.png'
import Image8 from '../assets/Images/Media&awards/Media4.png'
import Image9 from '../assets/Images/Media&awards/Media5.png'
import Image10 from '../assets/Images/Media&awards/Media6.png'
import Image11 from '../assets/Images/Media&awards/Media7.png'
import Image12 from '../assets/Images/Media&awards/Media8.png'
import Image13 from '../assets/Images/Media&awards/Media9.png'

export default function Partnership() {

   const data = [
    {
     img:Image1,
     link:"https://www.businesstoday.in/magazine/the-buzz/story/presolv360-dispute-management-on-the-cloud-108636-2018-07-09"
   },
   {
    img:Image2,
    link:""
   
  },   {
    img:Image3,
    link:"https://www.linkedin.com/feed/update/urn:li:activity:6401710711680143360/"
  
  },   {
    img:Image4,
    link:"https://www.livelaw.in/law-firms/presolv360-odr-advisory-council-246921"
 
  },   {
    img:Image5,
    link:"https://yourstory.com/2023/05/using-digital-means-to-solve-legal-disputes-startups-odr"
 
  },   {
    img:Image6,
    link:"https://timesofindia.indiatimes.com/india/as-lockdown-slows-down-courts-e-mediation-across-cities-picks-up/articleshow/78204960.cms"
  
  }, {
    img:Image7,
    link:"https://www.businessworld.in/article/Dispute-Resolution-at-a-Click/25-07-2023-485430/"
  },
  {
   img:Image8,
   link:"https://soundcloud.com/hrishikay/hrishi-k-with-namita-shah-aman-sanghvi-presolv360-mediation-out-of-court-settlements"
 
 },   {
   img:Image9,
   link:"https://mediate.com/indias-internet-citizens-can-finally-settle-online/"
 },   {
   img:Image10,
   link:"https://www.artificiallawyer.com/2020/03/16/in-india-civil-cases-take-13-years-but-presolv360-has-a-better-solution/"
 },   {
   img:Image11,
   link:"https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/people-in-distress-are-dialing-legal-tech-startups/articleshow/76637752.cms"
    ,width:"75%"
  },   {
   img:Image12,
   link:"https://www.thefinancestory.com/legatech-startup-by-chartered-accountant-in-india",
   width:"75%"
 }, {
  img:Image13,
  link:"https://www.thehindu.com/news/cities/mumbai/a-startup-that-helps-you-avoid-courts/article24618326.ece"
},
  ]
  return (
    <div className='section mediasection' style={{paddingTop:"0em"}}>
             <div className='section_heading'style={{paddingTop:"0em"}}>
          <h2 className='blue_heading' data-aos="fade-up" data-aos-duration="1500">Media & Awards</h2>
        
        </div>
         <Marqueee data={data}/>
        </div>
  )
}
