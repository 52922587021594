import axios from "axios";
import { getCookie } from "../utils/getCookies";
 const baseUrl = "https://presolv360.com/v1/public/api/"
const Chat = (data1) =>{
 
    
    let config = {
      method: 'post',
      url: `${baseUrl}codychatask`,
      data : {"req_message": data1,
      "conversation_id": getCookie("conversation_id"),
      "person_id":  getCookie( "person_id")
    }
    };
    return axios.request(config)
}

const getID = () => {
       axios.get(`${baseUrl}codychat`)
        .then((res)=>{
          let date = new Date();
          date.setTime(date.getTime() + (1 * 6 * 60 * 60 * 1000));
          const expires = "expires=" + date.toUTCString();
          document.cookie = `conversation_id=${res.data.conversation_id}; `+ expires + "; path=/";
          document.cookie = `person_id=${res.data.person_id}; `+ expires + "; path=/";
        })
}

export{Chat,getID}