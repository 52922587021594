import React,{useState} from 'react'
import Pagination from './Pagination';

export default function Videos() {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const videoData = [
      {
        tittle:"The Mediation Act, 2023: A 360-Degree Overview",
        description:`The panel discussion, titled "The Mediation Act, 2023: A 360-Degree Overview," offers an in-depth exploration of the Act's key features and its anticipated impact on legal practices. "`,

        videoLink:"https://www.youtube.com/watch?v=EsBWsIJGdbk"
         
    },
      {
     tittle:"Ep 2: How Women are Transforming India in the Legal-Tech Space? Namita Shah",
      description:`Today lets know about how we can get our small legal disputes solved using online dispute resolution platform. Lets enter the Legal Tech Space. Namita Shah the co-founder of Presolv360 built an amazing solution for all the small to medium level disputes that don't see the light of court . She with her two founder Aman Sanghavi and Bhaven Shah have designed a cost effective, less time consuming and hassle free solution to resolve disputes using technology by arbitration, mediation, and negotiation.`,
      videoLink:"https://www.youtube.com/watch?v=qSj3Uxm6U_4"
      },
     
    {
     tittle:"Interview with Bhaven Shah, Co-Founder, PRESOLV360",
     description:"In an interview with Bhaven Shah, Co-Founder of Presolv360, we speak to him about his journey as a CA student who has also done law and eventually founded Presolv360. He also takes us through the entire journey of Presolv360, starting from the idea to the inception and implementation. ",
     videoLink:"https://www.youtube.com/watch?v=GYMMs-3nOek"
    },
            {
              tittle:"ODR for Commercial Disputes: The Business-Friendly Approach | Panel Discussion",
              description:"Presolv360 in association with Law Ninja's hosted a panel discussion on the topic - Online Dispute Resolution for Commercial Disputes: The Business-Friendly Approach.",
              videoLink:"https://www.youtube.com/watch?v=OAsQ7azGnos"
              
              },

              {
                tittle:"Interviewing Mr. Maneck Mulla - Founder & Managing Partner at M Mulla Associates.",
                description:"With over 90 lakh civil cases pending and the average time to dispose each of them being 15 years, it becomes paramount that the Ministry of Law and Justice and private ODR players collectively work to provide accessible, cost-effective and timely justice. This entire situation has ushered in the realization that it is time for disputes to go online",
                videoLink:"https://www.youtube.com/watch?v=0pZXt2FjTU4"
                
                },
          
                {
                  tittle:"Interviewing Mr. Sriram Panchu - International Mediator and Senior Advocate",
                  description:"Resolving disputes from the comfort of one's home provides a shade of safety for warring parties to open up and talk about their interest. This entire situation has ushered in the realization that it is time for disputes to go online.",
                  videoLink:"https://www.youtube.com/watch?v=ni-k3Oj2G_M"
                  
                  },

                  {
                    tittle:"Dispute Resolution in Changing Times - Interview | Mr. Soli Cooper - Senior Counsel at Supreme Court",
                    description:"The traditional dispute resolution mechanisms like courts are plagued with a number of serious problems. Thus, it becomes imperative that simple and small value disputes be resolved without recourse to courts using arbitration and mediation. This ensures that they are resolved quickly and cost-effectively. This entire situation has ushered in the realization that it is time for disputes to go online.",
                    videoLink:"https://www.youtube.com/watch?v=l6TRFntt77s"
                    
                    },
                  
                    {
                      tittle:"Presolv360 Webinar with IDIA | ODR: The Future of Dispute Resolution, Career Opportunities & More",
                      description:"Presolv360 hosted a webinar with IDIA on the topic: 'ODR: The Future of Dispute Resolution, Career Opportunities & More'.",
                      videoLink:"https://www.youtube.com/watch?v=Seuiq1dJAPY"
                      
                    },
                    {
                      tittle:"Dispute Resolution in Changing Times - Interview | Chief Justice (Rtd.) Pradeep Nandrajog, Bombay HC",
                      description:"The five chariots - traditional courts, mediation, arbitration, conciliation and adjudicatory forums, are instrumental in dispensing justice in the country. The challenges faced by the courts have crippled it, and hence it becomes imperative for mediation and arbitration to dispense timely, cost-effective and accessible justice. This entire situation has ushered in the realization that it is time for disputes to go online.",
                      videoLink:"https://www.youtube.com/watch?v=kfCa8JoW-ns"
                      
                      },

                      {
                        tittle:"Dispute Resolution in Changing Times - Interview | Ms. Rajani Iyer - Senior Counsel and Mediator",
                        description:"Today, there are millions of people in the remotest of our cities, towns and villages who have no access to justice, but they do have access to smart phones. With the help of technology, we can improve the access to justice significantly and bring equity at the fingertips of each and every Indian irrespective of his or her geographic location. This entire situation has ushered in the realization that it is time for disputes to go online.",
                        videoLink:"https://www.youtube.com/watch?v=TjOyFnNfz4U"
                        
                        },
                        {
                          tittle:"Dispute Resolution in Changing Times - Interview with Mr. Prathamesh D Popat - Mediator and Counsel",
                          description:"Resolving disputes from the comfort of one's home provides a shade of safety for warring parties to open up and talk about their interest. This entire situation has ushered in the realization that it is time for disputes to go online.",
                          videoLink:"https://www.youtube.com/watch?v=YnwnYv6KsVw"
                          
                          },
                          {
                            tittle:"Presolv360 - IMC Webinar | Mediating and Arbitrating Online with Presolv360",
                            description:"Presolv360 and IMC's Mediation & Conciliation Committee jointly hosted a webinar: 'Mediating and Arbitrating Online with Presolv360'.",
                            videoLink:"https://www.youtube.com/watch?v=AaKjszGqLC8"
                            
                            },
                            {
                              tittle:"Presolv360 - LexADR Webinar | Online Dispute Resolution in COVID-19 times and beyond",
                              description:"Presolv360 and LexADR jointly hosted a webinar: 'Online Dispute Resolution in COVID-19 times and beyond'.",
                              videoLink:"https://www.youtube.com/watch?v=JaUQvOnH8cw"
                              
                              },
                              {
                                tittle:"Dispute Resolution in Changing Times - Interview with HMJ K. Kannan, Judge (Rtd) Punjab & Haryana HC",
                                description:"Disputes are inevitable but how you resolve them is a choice. The Indian judiciary is burdened with a backlog of cases. As of today, there are 3.5+ crore cases pending in Indian courts, and on an average it takes 13 years for a dispute to get resolved. Dispute resolution through traditional mechanisms is costly, time consuming and inefficient. This entire situation has ushered in the realization that it is time for disputes to go online.",
                                videoLink:"https://www.youtube.com/watch?v=UozMurcWIcU"
                                
                                },
                               
                                {
                                  tittle:"Dispute Resolution in Changing Times - Interview | Ms. Tanu Mehta - Counsel, Mediator, Conciliator",
                                  description:"The current healthcare crisis has brought a standstill to life across the globe. Law and order being crucial need to maintained even during such trying times. While we understand that access to courts is limited, access to justice is not and should never be. This entire situation has ushered in the realization that it is time for disputes to go online.",
                                  videoLink:"https://www.youtube.com/watch?v=7ucnjGw1AvY"
                                  
                                },
                                {
                                  tittle:"Dispute Resolution in Changing Times - Interview | Mr. Ashok Barat - Fmr. MD & CEO, Forbes & Co Ltd.",
                                  description:"During these unprecedented times, it is imperative that courts, tribunals and various dispute resolution bodies continually dispense justice while abiding to the ‘Social Distancing’ norm. This entire situation has ushered in the realization that it is time for disputes to go online. ",
                                  videoLink:"https://www.youtube.com/watch?v=wtGSvrd_rhk"
                                  
                              },    



        {
            tittle:"Presolv360 - New Law College (BVDU), Pune | Arbitrating and Mediating Online",
            description:"Presolv360 and 'Madhyasthta'- The ADR Cell of New Law College, Pune, jointly hosted a webinar: 'Arbitrating and Mediating Online'. ",
            videoLink:"https://www.youtube.com/watch?v=UxL7m4te5N8"
            
        }
      
                    ]
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = videoData.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(videoData.length / recordsPerPage)
  return (
    <section className='poadcast_main_wrap'>

    {
        currentRecords.map((poadcast)=>{
            return (
                <div className='resource_wrap'>
                <div className='course_right_wrap'>
                <img className='thumbnail' src={`http://i3.ytimg.com/vi/${poadcast.videoLink.split('=').slice(-1).toString()}/hqdefault.jpg`} style={{width:"85%"}} alt='videosimage'/>
               </div>
               <div className='resource_left_wrap'>
                 <h3>{poadcast.tittle}
        </h3> 
        <p style={{lineHeight:"140%"}} >{poadcast.description}
        </p>
        <div className='resourse_social_media_wrap'>
            <a className='orange_btn' target='_blank' rel="noreferrer" href={poadcast.videoLink}>Watch</a>
        </div>
               </div>
                </div>
            )
        })
    }
    <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
    />
    </section>
  )
}

