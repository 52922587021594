import React from 'react'
import Slider from "react-slick";
import Image1 from '../assets/Images/PoadcastImages/Ep8.png'
import Image2 from '../assets/Images/Global_speaking.png'
import Poad1 from '../assets/Images/Podcast Logos.png'
import Image3 from '../assets/Images/Blog 1.png'
import Poad2 from '../assets/Images/Podcast Logos(1).png'
import Poad3 from '../assets/Images/Podcast Logos(2).png'
import Poad4 from '../assets/Images/Podcast Logos(3).png'
import Case12Image from '../assets/Images/caseAlertImages/Case12.png'
export default function Resource() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    arrows: false,
    dotsClass:'sliderDots2',
    customPaging: i => (
      <div
      >
        {i + 1}
      </div>
    )
};
  return (
    <div className='section'>
    <div className='section_heading' style={{paddingTop:"0em"}}>
        <h2 className='blue_heading'style={{color:"#fff"}} data-aos="fade-up" data-aos-duration="1500">Resource Center</h2>
      </div>


    <div>
    <Slider {...settings}>
      <div>
        <div className='resource_wrap'>
        <div className='course_right_wrap'>
        <img src={Image1} alt='Odr on Air'/>
       </div>
       <div className='resource_left_wrap'>
       <h3  style={{color:"var(--color_2)",fontWeight:"800"}}>ODR on Air
</h3>
         <h3  style={{color:"#fff",marginTop:".5em"}}>Bills, Acts & eCourts: India's Ambition to Become a Global ODR Hub
</h3> 
<p style={{color:"#fff",letterSpacing:"2px"}} >
</p>
<h3  style={{color:"#fff"}}>Listen on</h3>
<div className='resourse_social_media_wrap'>
  <a href='https://podcasts.apple.com/in/podcast/8-bills-acts-ecourts-indias-ambition-to-become-a/id1688518562?i=1000629462014' target='_blank' rel="noreferrer"><img src={Poad1} alt='poad1'/></a>
  <a href='https://open.spotify.com/episode/5Kz9T91XR8qiN1UmOg0R18?si=DbQACc-DRUapsJ5XB_fD-w&utm_source=copy-link' target='_blank' rel="noreferrer"><img src={Poad2} alt='poad2'/></a>
  <a href='https://podcastsmanager.google.com/episode?show=show%3AnMfD3iiR1o9RVop7vz0CSg&episode=aHR0cHM6Ly9zcGhpbnguYWNhc3QuY29tL3Avb3Blbi9zLzY0NTQ5YzgxMThkYmQ1MDAxMjJhMjEyMy9lLzY0ZjMwMjQwYTIxMTY1MDAxMTYwYTEwNC9tZWRpYS5tcDM&daterange=LAST_28D&hl=en' target='_blank' rel="noreferrer"><img src={Poad3} alt='Poad3'/></a>
  <a href="https://music.amazon.in/podcasts/d742a323-4d15-4fbc-ba12-f9888fc87cbd/episodes/44dbe0af-3c1a-40ec-a049-f754bfdff4d0/settle-the-battle-by-presolv360-8-bills-acts-ecourts-india's-ambition-to-become-a-global-odr-hub'" target='_blank' rel="noreferrer"><img src={Poad4} alt='Poad4'/></a>
</div>
       </div>
        </div>
      
      </div>

      <div>
        <div className='resource_wrap'>
        <div className='course_right_wrap'>
        <img src={Image2} alt='Globally Speaking'/>
       </div>
       <div className='resource_left_wrap'>
       <h3  style={{color:"var(--color_2)",fontWeight:"800"}}>Globally Speaking
</h3> 
         <h3 style={{color:"#fff"}}>An Interview with Rahim Shamji, Founder, ADR ODR International
</h3> 
<a href='https://presolv360.com/resources/globally-speaking-interview-rahim-shamji-founder-adr-odr-international/' target='_blank' rel="noreferrer" className='orange_btn'>Learn More</a>
       </div>
        </div>
      
      </div>


      <div>
        <div className='resource_wrap'>
        <div className='course_right_wrap'>
        <img src={Image3} alt='Blog'/>
       </div>
       <div className='resource_left_wrap'>
       <h3  style={{color:"var(--color_2)",fontWeight:"800"}}>Blog
</h3> 
         <h3 style={{color:"#fff"}}>Institutional Arbitration On
Disputes related to small-value leading
</h3> 

         <a href='https://presolv360.com/resources/institutional-arbitration-breaking-deadlock-small-value-lending-disputes/' target='_blank' rel="noreferrer" className='orange_btn'>Learn More</a>
       </div>
        </div>
      
      </div>
      <div>
      <div className='resource_wrap'>
                <div className='course_right_wrap'>
                <img className='thumbnail' src={Case12Image} style={{width:"85%"}} alt='Case Alerts'/>
               </div>
               <div className='resource_left_wrap'>
               <h3  style={{color:"var(--color_2)",fontWeight:"800"}}>Case Alerts
</h3> 
                 <h3 style={{color:"#fff"}}>ODR-administered Arbitration finds Acceptance in Execution Proceedings Despite Unilateral Arbitrator Appointment Clause in the Agreement</h3> 
  
        <div className='resourse_social_media_wrap'>
            <a className='orange_btn' href="/case-alert">Learn More</a>
        </div>
               </div>
                </div>
      </div>
      <div>
      <div className='resource_wrap'>
                <div className='course_right_wrap'>
                <img className='ODR TV' src={`http://i3.ytimg.com/vi/EsBWsIJGdbk/hqdefault.jpg`} style={{width:"85%"}} alt='image1'/>
               </div>
               <div className='resource_left_wrap'>
               <h3  style={{color:"var(--color_2)",fontWeight:"800"}}>ODR TV
</h3> 
                 <h3 style={{color:"#fff"}}>The Mediation Act, 2023: A 360-Degree Overview
        </h3> 
      
        <div className='resourse_social_media_wrap'>
            <a className='orange_btn' target='_blank' rel="noreferrer" href="https://www.youtube.com/watch?v=EsBWsIJGdbk">Watch</a>
        </div>
               </div>
                </div>
      </div>


       </Slider>
      
    </div>
  </div>
  )
}

