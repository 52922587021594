import React from 'react'
import Image1 from '../../../assets/Images/oped/image 4.png'
import Image2 from '../../../assets/Images/oped/barandbench_2021-12_b8659a52-76db-493e-8986-6a96bcbe5ceb_WhatsApp_Image_2021_12_19_at_9_48_51_AM 1.png'
import Image3 from '../../../assets/Images/oped/image 6.png'
import Image4 from '../../../assets/Images/oped/ipr-for-corporate-her 1.png'
import Image5 from '../../../assets/Images/oped/image 5.png'
import Image6 from '../../../assets/Images/oped/bigimag.png'
import Image7 from '../../../assets/Images/oped/image 8.png'
export default function Page1() {
  return (
    <div className='pageBody'>
      <div className='oped_leftSection'>
           <a  rel="noreferrer"  href="https://www.thehindu.com/opinion/op-ed/india-must-board-the-online-dispute-resolution-bus/article66729863.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image1} alt="oped_1" />
      <h3>India must board the Online Dispute Resolution bus</h3>
           </a>
           <a  rel="noreferrer"  href="https://www-barandbench-com.cdn.ampproject.org/c/s/www.barandbench.com/amp/story/columns/indias-hidden-judicial-challenge-when-will-the-oldest-case-awaiting-disposal-balram-kashyap-vs-bhagirathi-ganju-find-closure" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image2} alt="oped_2" />
      <h3>India's hidden judicial challenge: When will the oldest case awaiting disposal find closure?</h3>
           </a>
           <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/online-dispute-resolution/article67274567.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image3} alt="oped_3" />
      <h3>Online dispute resolution</h3>
          </a>
           <a  rel="noreferrer"  href="https://economictimes.indiatimes.com/opinion/et-commentary/how-online-dispute-resolution-can-be-a-game-changer-for-protecting-ipr-of-women-entrepreneurs/articleshow/99824112.cms" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image4} alt="oped_4" />
      <h3>How online dispute resolution can be a game changer for protecting IPR of women entrepreneurs
</h3>
           </a>
      </div>
      <div>
      <a  rel="noreferrer"  href="https://yourstory.com/socialstory/2023/04/case-for-using-online-dispute-resolution-audio-impaired" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image5} alt="oped_5" />
      <h3>Hear the Difference : THE CASE FOR USING ODR TO IMPROVE LEGAL DISPUTES</h3>
           </a>
           <a  rel="noreferrer"  href="https://bwlegalworld.businessworld.in/article/The-Indian-Legal-System-Is-It-Delivering-Its-Objectives-/06-06-2023-479346/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image6} alt="oped_6" />
      <h3>The Indian Legal System: Is It Delivering Its Objectives?</h3>
     
           </a>
        <div className='oped_flex'data-aos="fade-up" data-aos-duration="1500">
        <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/online-dispute-resolution/article67147413.ece" target="_blank" className='card_oped'>
    <img src={Image3} alt="oped_3" />
      <h3>Online dispute resolution</h3>           </a>
 <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/dispute-settlement-needs-out-of-court-mechanisms/article67684083.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image7} alt="oped_7" />
      <h3>Dispute settlement needs out-of-court mechanisms</h3>         </a>
        </div>
      </div>
    </div>
  )
}
