import React from 'react'
import AnimatedBox from '../components/AnimatedBox'
import { Helmet } from 'react-helmet'
import { ToastContainer} from 'react-toastify';
export default function Clauses() {

    function CopyData (id) {
       const textChild = ['copy1','copy2','copy3']
   const quoteText = document.getElementById(id);
	let text = quoteText.textContent;
	navigator.clipboard.writeText(`${text}`);
        for(let i =0 ; i<textChild.length ; i++){
           if(textChild[i] === id){
            document.getElementById(textChild[i]).style.backgroundColor="var(--color_2)"
           }
           else {
            document.getElementById(textChild[i]).style.backgroundColor="transparent"
           }
        }
    }

  return (
    <div className='clausePage'> 
        <ToastContainer/>
    <Helmet>
    <title>Presolv360 | Dispute Resolution Clause | Arbitration | Mediation | Conciliation</title>
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
<meta name="msapplication-TileImage" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<link rel="canonical" href="https://presolv360.com/clause" />
<meta name="description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />

<meta name="keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />

<meta property="og:keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<meta property="og:description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />
<meta property="og:image" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:image:secure_url" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:url" content="https://www.presolv360.com"/>
<meta property="og:title" content=" Presolv360 | Dispute resolution made easy" />
<meta property="og:type" content="article" />
    </Helmet>
    <div className='firstBreak'>
    <section className='landing_wrap'>
      {
        window.innerWidth > 600 ?<h1 style={{padding:"0 1.5em"}}>
    Dispute Resolution Clause 
        </h1> : <h1>
        Dispute Resolution Clause
        </h1>
      }
          <AnimatedBox/>
      </section>
    </div>
    <div className='extra_section_wrap'> 
         {/* <h3>Dispute Resolution Clause</h3> */}
         <p>To adopt Presolv360’s online dispute resolution mechanism, we recommend that parties incorporate one of the following dispute resolution clauses in their contract :</p>
  <h3>Arbitration Clause</h3>
  <p>Arbitration is a dispute resolution mechanism wherein parties are bound by an award made by the arbitrator. Parties should opt for arbitration if they desire a decision by an independent adjudicator.</p>
     <div className='copy_wrap'>
        <button className='blue_btn copybtn' onClick={()=>{CopyData('copy1')}}>Copy</button>
        <div className='copyboard' id='copy1'>
            <p>Notwithstanding anything to the contrary contained herein, any dispute, controversy and / or claim arising out of and / or relating to this contract, including its construction, interpretation, meaning, scope, operation, effect and / or validity thereof (“Dispute”), shall be resolved by arbitration, administered by Presolv360, an independent institution, in accordance with its Dispute Resolution Rules (“Rules”).
</p>
<p>The parties agree that the arbitration shall be before a sole arbitrator appointed under the Rules. The juridical seat of arbitration shall be ___, India. The language of arbitration shall be English. The law governing the arbitration proceedings shall be Indian law. The decision of the arbitrator shall be final and binding on the parties. Subject to the above, the competent courts at the seat shall have exclusive jurisdiction.
</p>
<p>The parties agree to carry out the arbitration proceedings virtually through the online dispute resolution (“ODR”) platform of Presolv360 (https://presolv360.com/) and, for such purpose, the email addresses and / or mobile numbers available, provided or otherwise referenced in the contract shall be considered. Each party shall be responsible for intimating such institution in the event of any change in its email address and / or mobile number throughout the arbitration proceedings. In the event the arbitration proceedings cannot be administered virtually in the opinion of the arbitrator, the proceedings shall be conducted physically, and the venue of the proceedings shall be as determined by the arbitrator having regard to the circumstances of the case, including the convenience of the parties.</p>
        </div>
     </div>

     <h3>Mediation Clause</h3>
     <p>Mediation is a dispute resolution mechanism wherein parties mutually opt to settle the dispute facilitated by a mediator. Parties should opt for mediation if they desire a negotiated settlement.</p>
    <div className='copy_wrap'>
     <button className='blue_btn copybtn' onClick={()=>{CopyData('copy2')}} >Copy</button>
        <div className='copyboard' id='copy2'>
    <p>Notwithstanding anything to the contrary contained herein, any dispute, controversy and / or claim arising out of and / or relating to this contract, including its construction, interpretation, meaning, scope, operation, effect and / or validity thereof (“Dispute”), shall be resolved by mediation, administered by Presolv360, an independent institution, in accordance with its Dispute Resolution Rules.
</p>
    <p>The parties agree to carry out the mediation proceedings virtually through the online dispute resolution (“ODR”) platform of Presolv360 (https://presolv360.com/) and, for such purpose, the email addresses and / or mobile numbers available, provided or otherwise referenced in the contract shall be considered. Each party shall be responsible for intimating such institution in the event of any change in its email address and / or mobile number throughout the mediation proceedings.
</p>

        </div>
    </div>
       <h3>Med-Arb Clause</h3>
       <p>Med-Arb is a process wherein a dispute is first referred to mediation, and if it is unsuccessful, the dispute is then finally settled by arbitration.</p>
      <div className='copy_wrap'>

        <button className='blue_btn copybtn' onClick={()=>{CopyData('copy3')}}>Copy</button>
          <div className='copyboard' id='copy3'>
            <p>Notwithstanding anything to the contrary contained herein, any dispute, controversy and / or claim arising out of and / or relating to this contract, including its construction, interpretation, meaning, scope, operation, effect or validity thereof (“Dispute”), shall be resolved by mediation, administered by Presolv360, an independent institution, in accordance with its Dispute Resolution Rules (“Rules”).
</p>
            <p>If the mediation is a non-starter or unsuccessful or the parties are unable to resolve the Dispute within a period of 45 days, the Dispute shall thereafter be finally resolved by arbitration, administered by Presolv360 in accordance with its Rules.
</p>
            <p>The parties agree that the arbitration shall be before a sole arbitrator appointed under the Rules. The juridical seat of arbitration shall be ___, India. The language of arbitration shall be English. The law governing the arbitration proceedings shall be Indian law. The decision of the arbitrator shall be final and binding on the parties. Subject to the above, the competent courts at the seat shall have exclusive jurisdiction.
</p>
            <p>
The parties agree to carry out the proceedings virtually through the online dispute resolution (“ODR”) platform of Presolv360 (https://presolv360.com/) and, for such purpose, the email addresses and / or mobile numbers available, provided or otherwise referenced in the contract shall be considered. Each party shall be responsible for intimating such institution in the event of any change in its email address and / or mobile number throughout the proceedings. In the event the arbitration proceedings cannot be administered virtually in the opinion of the arbitrator, the proceedings shall be conducted physically, and the venue of the proceedings shall be as determined by the arbitrator having regard to the circumstances of the case, including the convenience of the parties.</p>
          </div>
      </div>
      
      <p>The dispute resolution clause should be inserted under the heading “Dispute Resolution” or under a similar section in the contract. Please ensure that there are no conflicting dispute resolution clauses under the heading “Dispute Resolution” or “Arbitration” or under a similar section in the contract. In case the contract is already executed, parties can still opt for Presolv360’s online dispute resolution mechanism by agreeing to one of the above mechanisms by an exchange of letters / emails.
</p>
      <p>The email address and mobile numbers provided by each party shall be one which is regularly accessed and which will be used to register on Presolv360’s platform to receive communications pertaining to a dispute that may arise.
</p>
      <p>Kindly ensure that the “Notice” clause in the contract enables parties to serve notices / communications by email, text message and tele-messaging applications such as WhatsApp.
</p>
      <p>For any queries, write to us at <a href="mailto:info@presolv360.com" target="_blank" rel="noopener noreferrer">info@presolv360.com</a>.</p>
    </div>

    </div>
  )
}
