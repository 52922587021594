import React from 'react'
import Image1 from '../../../assets/Images/oped/page3/image 9.png'
import Image2 from '../../../assets/Images/oped/page3/image 14.png'
import Image3 from '../../../assets/Images/oped/page3/image 10.png'
import Image4 from '../../../assets/Images/oped/page3/image 11.png'
import Image5 from '../../../assets/Images/oped/page3/Oped10 1.png'
import Image6 from '../../../assets/Images/oped/page3/Oped11 1.png'
import Image7 from '../../../assets/Images/oped/page3/image 12.png'
import Image8 from '../../../assets/Images/oped/page3/Oped13 1.png'
export default function Page3() {
  return (
    <div className='pageBody'>
      <div className='oped_leftSection'>
           <a  rel="noreferrer"  href="https://www.barandbench.com/columns/are-you-ready-for-the-aftermath-of-the-lockdown" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image1} alt="oped_1" />
      <h3>Are you ready for the aftermath of the COVID-19 lockdown?</h3>
           </a>
           <a  rel="noreferrer"  href="https://www.thehindubusinessline.com/opinion/online-dispute-resolution/article67274567.ece" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image2} alt="oped_2" />
      <h3>Online dispute resolution</h3>
           </a>
           <a  rel="noreferrer"  href="http://www.businessworld.in/article/Post-COVID-19-Businesses-Business-Disputes-More/12-05-2020-191858/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image3} alt="oped_3" />
      <h3>Post-COVID-19: Businesses, Business Disputes & More</h3>
         </a>
           <a  rel="noreferrer"  href="https://www.google.co.in/amp/businessworld.in/amp/article/Cure-For-The-Tareekh-Pe-Tareekh-Virus/22-03-2020-186819" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image4} alt="oped_4" />
      <h3>Cure For The 'Tareekh Pe Tareekh' Virus
</h3>
           </a>
      </div>
      <div>
      <a  rel="noreferrer"  href="https://www.youthkiawaaz.com/2020/05/business-disputes-as-a-result-of-covid-19/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image5} alt="oped_5" />
      <h3>Business Disputes As A Result Of COVID-19: What Will Be The Solution?</h3>

           </a>
           <a  rel="noreferrer"  href="http://www.businessworld.in/article/Justice-Should-Be-Served-Not-Adjourned/05-05-2020-191125/" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image6} alt="oped_6" />
      <h3>Justice Should Be Served, Not Adjourned</h3>
     
           </a>
        <div className='oped_flex'data-aos="fade-up" data-aos-duration="1500">
        <a  rel="noreferrer"  href="https://www.barandbench.com/columns/online-dispute-resolution-a-possible-cure-to-the-virus-plaguing-the-justice-delivery-system" target="_blank" className='card_oped'>
    <img src={Image7} alt="oped_3" />
      <h3>Online Dispute Resolution: A possible cure to the virus plaguing the Justice Delivery System?</h3>         </a>
 <a  rel="noreferrer"  href="https://www.entrepreneur.com/en-in/technology/here-is-everything-you-need-to-know-about-online-dispute/316222" target="_blank" className='card_oped'data-aos="fade-up" data-aos-duration="1500">
    <img src={Image8} alt="oped_7" />
      <h3>The Ball is Now 'Out of Court</h3>         </a>
        </div>
      </div>
    </div>
  )
}
