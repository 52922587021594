import React from 'react'

export default function Case9() {
  return (
    <>
       <b>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
   </b>
   
   <div>
   <i>Case Title: Mathew P J v M/S. Cholamandalam Investment And Finance Co Ltd

  
   </i>
   <i>
   Court: Kerala High Court
 
   </i>
   <i>Date of Judgement: 19.12.2023</i>
   </div>
    <p style={{paddingTop:"1.5em"}} >
    In the case of Mathew P J v. M/S. Cholamandalam Investment And Finance Co Ltd the Hon’ble Kerala High Court has made a crucial ruling regarding the limitation period for challenging arbitral awards. The Hon’ble Kerala High Court has held that Section 5 of the Limitation Act 1963 (“Limitation Act”) is inapplicable for condoning delays in filing applications under Section 34 of the Arbitration and Conciliation Act 1996 (“Arbitration Act”) since the Arbitration Act is a special statute.</p>  


 
   <p style={{paddingBottom:"1.5em"}}>
   The Hon’ble Court emphasized that Section 34 of the Arbitration Act specifies a maximum limitation period of four months to challenge an arbitral award. Consequently, any appeal filed beyond this period is barred by limitation and cannot be entertained. In the present case the appellants filed their arbitration appeal 1306 days after the stipulated period leading to the dismissal of their plea.</p>
   </>
  )
}
