import React from 'react'
import { Helmet } from 'react-helmet'
import AnimatedBox from '../../components/AnimatedBox'
export default function ResearchPaper() {
  return (
    <div>
          <Helmet>
  <title>ADR & ODR: The Future of Dispute Resolution</title>
<meta name="description" content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era." />
<meta name="keywords" content="dispute resolution global" />
<link rel="canonical" href="https://presolv360.com/research-paper" />
  </Helmet>
  <div className='firstBreak'>
  <section className='landing_wrap'>
    {
      window.innerWidth > 600 ?   <h1 style={{padding:"0 1.5em"}}>
      Dive into the depths of knowledge with our detailed research papers, where extensive study on various nuanced topics unfold
      </h1> :   <h1 style={{padding:"0 .3em"}}>
      Dive into the depths of knowledge with our detailed research papers, where extensive study on various nuanced topics unfold
      </h1>
    }
        <AnimatedBox/>
    </section>
  </div>
 
  <div className='researchpaper_wrap'>
       <div className='reserachPaper_child_wrap'>
         <h3>Enabling Online Dispute Resolution to Make India an IP-Friendly Jurisdiction
</h3> 
<p>The 2017 United Nations Commission on International Trade Law [“UNCITRAL”] Technical Notes on Online Dispute Resolution [“ODR”] defines ODR as a mechanism that facilitates resolution of disputes in a simple, fast, and flexible manner without the need for a physical presence of the disputing parties. This paper explores the possibility of establishing an ODR system in India to resolve Intellectual Property Rights [“IP”] disputes with a special focus on Trademark opposition disputes. To do so, it examines the shortcomings of the current dispute resolution process by looking at various case studies and reviewing ODR systems successfully adopted by other countries to resolve IP disputes.</p>
         <a  target='_blank' rel="noreferrer" href='https://www.rgnulcadr.in/post/enabling-online-dispute-resolution-to-make-india-an-ip-friendly-jurisdiction' className='orange_btn'  style={{maxWidth:"max-content"}}>Click here for the paper</a>
        </div>

        <div className='reserachPaper_child_wrap'>
         <h3>Online Dispute Resolution: Creating a Level-Playing Field in Small-Value Claims
</h3> 
<p>IRIArb focuses on research in both academic and practical aspects of international commercial and investment arbitration, and other connected areas of law. With the aim to provide for a balance between research on contemporary developments, and analysis of long-standing issues in international arbitration, IRIArb is dedicated to being a catalyst towards the progress of international arbitration through the publication of reliable and useful literature in the area of arbitration. Creating a platform to facilitate dialogues among stakeholders, ranging from contributors from the highest legal foras to current law students from different legal, linguistic and cultural backgrounds, IRIArb encourages previously unpublished papers that caters to developing an educated colloquy – that is contemporary, recent or novel.</p>
<a  target='_blank' rel="noreferrer"  href='https://www.volterrafietta.com/wp-content/uploads/2023/03/IRIArb-Vol.-2-Issue-2.pdf#page=103' className='orange_btn'  style={{maxWidth:"max-content"}}>Click here for the paper</a>
        </div>

        <div className='reserachPaper_child_wrap'>
         <h3>An Empirical Study 
</h3> 
<p>In recent years, technology has played a significant role in transforming the way we approach problem solving. ODR is one such solution that offers an efficient and effective means of resolving disputes without the need to litigate. The present report provides valuable information into an emerging mode of dispute resolution by analyzing data of actual arbitrations administered on Presolv360’s ODR platform.</p>
         <a  target='_blank' rel="noreferrer" href='https://drive.google.com/file/d/1RM8T4lnrUU_vmjTtzQD140OVf3Ua7iR6/view?usp=sharing' className='orange_btn'  style={{maxWidth:"max-content"}}>Click here for the paper</a>
        </div>

  </div>
 
      </div>
  )
}
