import React from "react"
import Marquee from "react-fast-marquee"
import "./style.css"
function Marqueee({data}) {
    return (
      
        <Marquee  style={{zIndex:"0"}} direction="left"  speed={60.45}>
          {
            data.map((element,index)=>{
                   return (
                <a href={element?.link} target="_blank" rel="noreferrer" className="marquee_img" style={{cursor: `${element.link ? 'pointer' : 'default'}`}} >
<img key={index} style={{width:`${element?.width}`}} src={element.img} alt={element.img.split('/').splice(-1)} /> 
                </a>
                   )
            })
          }

        </Marquee>
     
    )
}

export default Marqueee