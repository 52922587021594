
import { NavLink} from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/Images/logo.png';
import Hamburger from "../../components/HamburgerMenu";
import EnquiryModal from "../../components/EnquiryModal";
import LoginDropdown from "../../components/LoginDropdown";
 export default  function Navbar () {
  const [showNavbar, setShowNavbar] = useState(false);
  const { pathname } = useLocation();
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
   useEffect (()=>{
     console.log(pathname)
   })
   
//   gsap.registerPlugin(ScrollTrigger);
//   useLayoutEffect(() => {
//     const ctx = gsap.context(() => {
//         ScrollTrigger.create({
//             trigger: ".navbar",
//             pin: true,
//             id:"nabavr",
//             start: "0 0",
//             markers:true,
//             end:""
//         });
//     })
//     return () => ctx.revert()
// })


  return (
    <nav className="navbar" id="nav">
         <Hamburger/>
      <div className="container">
        <a href="/" className="logo">
           <img src={Logo} alt="logo"/>
        </a>
     
        <div className={`nav-elements ${showNavbar}`}>
          <ul>
          <li onClick={handleShowNavbar}>
              <NavLink exact to="/">Home</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink  exact  to="/about-us">About</NavLink>
            </li>
            <li className="dropdown">
    <div  class="dropbtn">Solutions</div>
    <div className="dropdown-content">
    <NavLink exact onClick={handleShowNavbar}  to="/solutions/negotiation">Negotiation</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="/solutions/mediation">Mediation</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/solutions/arbitration">Arbitration</NavLink>
    </div>
  </li>
  <li className="dropdown">
    <div class="dropbtn">Resources</div>
    <div className="dropdown-content">
    <NavLink exact onClick={handleShowNavbar} target="_blank"  to="/clause">Clause</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="https://trainings.presolv360.com/" target="_blank">ODR Course</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/case-alert">Case Alerts</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="https://presolv360.com/resources/" target="_blank">Blog</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="/odr-tv">ODR TV</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/research-paper">Research Papers</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="/odr-on-air"> ODR on Air</NavLink>
    <NavLink exact onClick={handleShowNavbar}  to="/chat360" target="_blank">Chat360</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/impression">Impressions</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/globally-speaking">Globally Speaking</NavLink>
    <NavLink exact  onClick={handleShowNavbar} to="/op-ed">Op-ed</NavLink>
    </div>
  </li>
    
            <li onClick={handleShowNavbar}>
              <NavLink exact  to="/career">Careers</NavLink>
            </li>
      
          </ul>
         
        
        </div>
        <div className="navbtn_grp">
          {
            pathname === "/" ?     <div id="nav_enq_btn" 
            style={{height:"30px"}}
            >
            <EnquiryModal tittle={'Business Enquiry'} />
          </div> : <div 
          style={{visibility:"visible",height:"30px"}}
          >
          <EnquiryModal tittle={'Business Enquiry'} />
        </div>
          }
       
        
        <LoginDropdown/>

          </div>
      </div>
      
    </nav>
  );
};