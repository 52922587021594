import React from 'react'
export default function Case2() {
  return (
 <>
 <b style={{textAlign:"center"}}>
Karnataka High Court: Penalties Under Stamp Act Not Applicable to Insufficiently Stamped Awards at Execution Stage</b>
 <div>
<i>Case Title: Shakeel Pasha and Ors v. City Max Hotels 

</i>
<i>
Court: Karnataka High Court

</i>
<i>Date of Judgement: 28.07.2023 </i>
</div>
 <p style={{paddingTop:"1em"}} >
 In a recent order, the Karnataka High Court has stated that <b> penalties under the Karnataka Stamp Act, 1957 are not applicable to insufficiently stamped arbitral awards at the juncture of their “enforcement or execution”.</b> This award unambiguously clarifies the legal parameters concerning the imposition of penalties on such awards during the execution proceedings.
</p>  



<p style={{paddingBottom:"1.5em"}}>
<b>The court specifically stated that </b>“[T]he concept of imposing penalty on an insufficiently stamped instrument cannot be applied to an arbitral award in an execution proceedings.” 
</p>
</>
  )
}
