import React from 'react'
export default function Case1() {
  return (
<>
<b>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
</b>
<div>
<i>Case Title: Ministry of Youth Affairs and Sports v. Ernst and Young Pvt Ltd1
</i>
<i>
Court: Delhi High Court
</i>
<i>Date of Judgment: 23.08.2023</i>
</div>
             <p style={{paddingTop:"1.5em"}} >The Delhi High Court recently passed a judgment that has affirmed that the delivery of arbitral awards via email is valid under the Arbitration Act.
</p>  

<p>In a recent decision, the Delhi High Court stated that the delivery of a scanned, signed copy
of <b>an arbitral award via email falls squarely within the ambit of valid delivery as
stipulated under Section 31(5) of the Arbitration and Conciliation Act, 1996.</b>This decision
clearly delineates the legal standing of electronic delivery methods in arbitration proceedings.
</p>
<p style={{paddingBottom:"1.5em"}}>
The court specifically stated that “The law has to keep its pace in tandem with the developing technology. <b>When service by email is an accepted mode of service, then sending
scanned signed copy of the award/order of the Arbitral Tribunal to the parties would be
a valid delivery</b> as envisaged under Section 31(5) of the Arbitration Act.” 
</p>
</>
  )
}
