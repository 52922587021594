import React from 'react'
import Marqueee from '../components/marquee'
// import Image1 from '../assets/Images/Aliences/Bombay High Court.png'
import Image2 from'../assets/Images/Aliences/CBFW_CoreLogo_NavyOrange_RGB-Sian-Hawkins 1.png'
// import Image3 from '../assets/Images/Aliences/CDSL.png'
import Image4 from '../assets/Images/Aliences/Img1-removebg-preview 1.png'
// import Image5 from '../assets/Images/Aliences/Kerala High court.png'
// import Image6 from'../assets/Images/Aliences/Kerala High court.png'
import Image7 from '../assets/Images/Aliences/Logo1.png'
import Image8 from '../assets/Images/Aliences/Logo2.png'
import Image9 from '../assets/Images/Aliences/Logo3.png'
import Image10 from '../assets/Images/Aliences/Logo4.png'
// import Image11 from '../assets/Images/Aliences/MOL.png'
import Image12 from '../assets/Images/Aliences/MSINS-Website-logo_official-1 1.png'
import Image13 from '../assets/Images/Aliences/NSRCEL Main logo 1.png'
// import Image14 from '../assets/Images/Aliences/ONDC.png'
import Image15 from '../assets/Images/Aliences/Cyril_amarchand.png'
import Image16 from '../assets/Images/Aliences/TSIC.png'
import Image17 from '../assets/Images/Aliences/ytpdguepa9guhihey7ws 1.png'
export default function Client() {
  const data = [
   {
    img:Image2,
   },
       {
    img:Image4,
    alt:"Live Law.png",
    link:"https://www.startupindia.gov.in/content/sih/en/search.html?query=Edgecraft&roles=Startup&page=0"
  },   
   
    {
    img:Image7,
    alt:"Live Law.png"
  },  
   {
    img:Image8,
    alt:"satyamev-jayate-removebg-preview 1.png"
  },
     {
    img:Image9,
    alt:"Frame 6.png"
  },
  {
    img:Image10,
    alt:"Frame 6.png",
    link:'https://alita.legal/join-us',
  },
  {
    img:Image12,
    alt:"Frame 6.png",
    link:"https://www.linkedin.com/posts/stemceo_top-24-winners-of-maharashtra-startup-week-activity-6987065363179995136--tvX/"
  },
  {
    img:Image13,
    alt:"Frame 6.png"
  },
  {
    img:Image16,
    alt:"Frame 6.png"
  }, {
    img:Image17,
    alt:"Frame 6.png"
  },
  {
    img:Image15,
    link:"https://www.cyrilshroff.com/prarambh/"
  },
  ]
  return (
    <div className='section' style={{paddingBottom:"0em"}}>
       <div className='section_heading'>
          <h2 className='blue_heading'data-aos="fade-up" data-aos-duration="1500">Alliances & Incubation</h2>
        </div>
         <div>
           <Marqueee data={data}/>
         </div>
    </div>
  )
}
