import React, { useEffect, useState } from 'react'
import AnimatedBox from '../../components/AnimatedBox'
import { Helmet } from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify';
import { Chat, getID } from '../../service/chat360';
export default function Chat360() {
     const [typing,setTyping] = useState(false)
       const [Chats,SetChats] = useState([])
    
      function goChat() {
             let message = document.getElementById('chat_input').value
               if (message === ""){
                 toast.info('Chat Box is Empty!')
                 return
               }
               setTyping(true)
               SetChats([...Chats,{class:'usertext',desc:message}])
               document.getElementById('chat_input').value =""
            Chat(message)
            .then((res)=>{
              if(res.status !== 200) {
                 toast.error("Something went Wrong!!")
                  setTyping(false)
              }

              SetChats([...Chats,{class:'usertext',desc:message},{class:'chattext',desc:res.data.content}])
              setTyping(false)
            })
            .catch((err)=>{
              toast.error("Something went Wrong!!")
              setTyping(false)
            })

      }

      useEffect(()=>{
         if(document.cookie.indexOf('person_id') === -1 && document.cookie.indexOf('conversation_id') === -1 ) {
            getID()
         }
      })
  return (
    <div className='chat360page'> 
        <ToastContainer/>
    <Helmet>
    <title>Presolv360 | Standards | Online Arbitration | Money Recovery</title>
  <meta name="description" content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era." />
  <meta name="keywords" content="dispute resolution global" />
    </Helmet>
    <div className='firstBreak'>
    <section className='landing_wrap'>
      {
        window.innerWidth > 600 ?<h1 style={{padding:"0 1.5em"}} data-aos="fade-right" data-aos-duration="1500">
     A state-of-the-art ODR bot by Presolv<span style={{color:"var(--color_2)"}}>360</span>
     <br></br>Introducing Chat<span style={{color:"var(--color_2)"}}>360</span>
        </h1> :  <><h1 style={{paddingBottom:"1em"}} data-aos="fade-right" data-aos-duration="1500">
        A state-of-the-art ODR bot by Presolv<span style={{color:"var(--color_2)"}}>360</span></h1>
         <h1>Introducing Chat<span style={{color:"var(--color_2)"}}>360</span></h1>
         </>
      }
          <AnimatedBox/>
      </section>
    </div>
    <div className='extra_section_wrap'>
        <p  data-aos="fade-up" data-aos-duration="1500">Chat360 constantly updates its knowledge in law and technology to answer your questions. Chat360 is powered by an AI chatbot that has been trained by our team, and may be used for information purposes only. Note that Presolv360 shall not be liable to any person in respect of its use. Further, Presov360 is not a law firm and does not provide legal advice. The information should not be construed as legal advice and does not have any evidentiary value.
</p>
        <p  data-aos="fade-up" data-aos-duration="1500">
        Enough legalese, say hello and experience Chat360...
</p>
   <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
   <h3 style={{color:"var(--color_1)"}}>Chat<span style={{color:"var(--color_2)"}}>360</span></h3>
            <svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M46.2698 14.9581V4.35349C46.9954 3.96279 47.4977 3.18139 47.4977 2.34419C47.4977 1.06046 46.4372 0 45.1535 0C43.8698 0 42.8093 1.06046 42.8093 2.34419C42.8093 3.23721 43.3116 3.96279 44.0372 4.35349V13.6744C43.814 13.6186 43.5907 13.5628 43.3674 13.507C43.1442 11.107 41.1349 9.26511 38.7349 9.26511H9.32093C6.86512 9.26511 4.91163 11.107 4.68837 13.507C4.46512 13.5628 4.24186 13.6186 4.0186 13.6744V4.35349C4.74419 3.96279 5.24651 3.18139 5.24651 2.34419C5.24651 1.06046 4.18605 0 2.90233 0C1.6186 0 0.55814 1.00465 0.55814 2.28837C0.55814 3.18139 1.06047 3.90698 1.78605 4.29767V14.9581C0.669767 16.0186 0 17.4698 0 19.0884V29.0232C0 31.8139 2.0093 34.1581 4.63256 34.6605V35.2744L3.79535 44.0372C3.68372 45.3209 5.07907 46.2139 6.13953 45.5442L15.014 40.2418H38.6791C41.2465 40.2418 43.3674 38.1209 43.3674 35.5535V34.6046C45.9907 34.1581 48 31.8139 48 29.0232V19.0884C48 17.4698 47.3302 16.0186 46.2698 14.9581ZM41.1349 35.6093C41.1349 36.9488 40.0186 38.0651 38.6791 38.0651H9.32093C7.9814 38.0651 6.86512 36.9488 6.86512 35.6093V13.8977C6.86512 12.5581 7.9814 11.4419 9.32093 11.4419H38.7349C40.0744 11.4419 41.1907 12.5581 41.1907 13.8977V35.6093H41.1349Z" fill="#005186"/>
  <path d="M21.6019 21.0422C21.6019 17.9724 19.0903 15.5166 16.0764 15.5166C13.0066 15.5166 10.5508 18.0282 10.5508 21.0422C10.5508 24.1119 13.0624 26.5678 16.0764 26.5678C19.0903 26.5678 21.6019 24.1119 21.6019 21.0422ZM13.7322 23.7212C13.2299 23.7212 12.8392 23.3306 12.8392 22.8282C12.8392 22.3259 13.2299 21.9352 13.7322 21.9352C14.2345 21.9352 14.6252 22.3259 14.6252 22.8282C14.6252 23.3306 14.1787 23.7212 13.7322 23.7212ZM17.1368 22.7166C15.7415 22.7166 14.5694 21.5445 14.5694 20.1492C14.5694 18.7538 15.7415 17.5817 17.1368 17.5817C18.5322 17.5817 19.7043 18.7538 19.7043 20.1492C19.7043 21.5445 18.588 22.7166 17.1368 22.7166Z" fill="#005186"/>
  <path d="M31.9846 15.459C28.9148 15.459 26.459 17.9706 26.459 20.9846C26.459 24.0543 28.9706 26.5101 31.9846 26.5101C35.0543 26.5101 37.5101 23.9985 37.5101 20.9846C37.5101 17.9706 34.9985 15.459 31.9846 15.459ZM29.6404 23.7194C29.1381 23.7194 28.7474 23.3287 28.7474 22.8264C28.7474 22.3241 29.1381 21.9334 29.6404 21.9334C30.1427 21.9334 30.5334 22.3241 30.5334 22.8264C30.5334 23.3287 30.0869 23.7194 29.6404 23.7194ZM33.045 22.7148C31.6497 22.7148 30.4776 21.5427 30.4776 20.1474C30.4776 18.752 31.6497 17.5799 33.045 17.5799C34.4404 17.5799 35.6125 18.752 35.6125 20.1474C35.6125 21.5427 34.4962 22.7148 33.045 22.7148Z" fill="#005186"/>
  <path d="M24.0012 31.3683C22.0478 31.3683 20.485 29.8055 20.485 27.8521H18.8105C18.8105 30.6986 21.1547 33.0427 24.0012 33.0427C26.8478 33.0427 29.1919 30.6986 29.1919 27.8521H27.5175C27.5175 29.7497 25.9547 31.3683 24.0012 31.3683Z" fill="#005186"/>
</svg>
   </div>
   <div className='chatConatiner' id='chatCont' data-aos="zoom-in">
    {
       Chats.map((chat)=>{
         return (
          <div className={chat.class}>
          <p >{chat.desc}</p>
          </div>
         )
       })
    }
     
     {
      typing &&     <div className='usertext'>
      <span className='typing_effect'><svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M46.2698 14.9581V4.35349C46.9954 3.96279 47.4977 3.18139 47.4977 2.34419C47.4977 1.06046 46.4372 0 45.1535 0C43.8698 0 42.8093 1.06046 42.8093 2.34419C42.8093 3.23721 43.3116 3.96279 44.0372 4.35349V13.6744C43.814 13.6186 43.5907 13.5628 43.3674 13.507C43.1442 11.107 41.1349 9.26511 38.7349 9.26511H9.32093C6.86512 9.26511 4.91163 11.107 4.68837 13.507C4.46512 13.5628 4.24186 13.6186 4.0186 13.6744V4.35349C4.74419 3.96279 5.24651 3.18139 5.24651 2.34419C5.24651 1.06046 4.18605 0 2.90233 0C1.6186 0 0.55814 1.00465 0.55814 2.28837C0.55814 3.18139 1.06047 3.90698 1.78605 4.29767V14.9581C0.669767 16.0186 0 17.4698 0 19.0884V29.0232C0 31.8139 2.0093 34.1581 4.63256 34.6605V35.2744L3.79535 44.0372C3.68372 45.3209 5.07907 46.2139 6.13953 45.5442L15.014 40.2418H38.6791C41.2465 40.2418 43.3674 38.1209 43.3674 35.5535V34.6046C45.9907 34.1581 48 31.8139 48 29.0232V19.0884C48 17.4698 47.3302 16.0186 46.2698 14.9581ZM41.1349 35.6093C41.1349 36.9488 40.0186 38.0651 38.6791 38.0651H9.32093C7.9814 38.0651 6.86512 36.9488 6.86512 35.6093V13.8977C6.86512 12.5581 7.9814 11.4419 9.32093 11.4419H38.7349C40.0744 11.4419 41.1907 12.5581 41.1907 13.8977V35.6093H41.1349Z" fill="#005186"/>
  <path d="M21.6019 21.0422C21.6019 17.9724 19.0903 15.5166 16.0764 15.5166C13.0066 15.5166 10.5508 18.0282 10.5508 21.0422C10.5508 24.1119 13.0624 26.5678 16.0764 26.5678C19.0903 26.5678 21.6019 24.1119 21.6019 21.0422ZM13.7322 23.7212C13.2299 23.7212 12.8392 23.3306 12.8392 22.8282C12.8392 22.3259 13.2299 21.9352 13.7322 21.9352C14.2345 21.9352 14.6252 22.3259 14.6252 22.8282C14.6252 23.3306 14.1787 23.7212 13.7322 23.7212ZM17.1368 22.7166C15.7415 22.7166 14.5694 21.5445 14.5694 20.1492C14.5694 18.7538 15.7415 17.5817 17.1368 17.5817C18.5322 17.5817 19.7043 18.7538 19.7043 20.1492C19.7043 21.5445 18.588 22.7166 17.1368 22.7166Z" fill="#005186"/>
  <path d="M31.9846 15.459C28.9148 15.459 26.459 17.9706 26.459 20.9846C26.459 24.0543 28.9706 26.5101 31.9846 26.5101C35.0543 26.5101 37.5101 23.9985 37.5101 20.9846C37.5101 17.9706 34.9985 15.459 31.9846 15.459ZM29.6404 23.7194C29.1381 23.7194 28.7474 23.3287 28.7474 22.8264C28.7474 22.3241 29.1381 21.9334 29.6404 21.9334C30.1427 21.9334 30.5334 22.3241 30.5334 22.8264C30.5334 23.3287 30.0869 23.7194 29.6404 23.7194ZM33.045 22.7148C31.6497 22.7148 30.4776 21.5427 30.4776 20.1474C30.4776 18.752 31.6497 17.5799 33.045 17.5799C34.4404 17.5799 35.6125 18.752 35.6125 20.1474C35.6125 21.5427 34.4962 22.7148 33.045 22.7148Z" fill="#005186"/>
  <path d="M24.0012 31.3683C22.0478 31.3683 20.485 29.8055 20.485 27.8521H18.8105C18.8105 30.6986 21.1547 33.0427 24.0012 33.0427C26.8478 33.0427 29.1919 30.6986 29.1919 27.8521H27.5175C27.5175 29.7497 25.9547 31.3683 24.0012 31.3683Z" fill="#005186"/>
</svg>typing...</span>
      </div>
     }

     <div className='chatinput_wrap'>
     <input type='text' id='chat_input'  onKeyUp={(e)=>{
        if(e.key === "Enter") {
           goChat()
        }
     }} placeholder='Type your message here '/>
     <span>
     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" onClick={()=>{goChat()}} xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6_1270)">
    <path d="M33.7252 9.47665C34.4452 7.48499 32.5152 5.55499 30.5236 6.27665L6.1819 15.08C4.18357 15.8033 3.9419 18.53 5.78023 19.595L13.5502 24.0933L20.4886 17.155C20.8029 16.8514 21.2239 16.6834 21.6609 16.6872C22.0979 16.691 22.5159 16.8663 22.8249 17.1753C23.1339 17.4843 23.3092 17.9023 23.313 18.3393C23.3168 18.7763 23.1488 19.1973 22.8452 19.5117L15.9069 26.45L20.4069 34.22C21.4702 36.0583 24.1969 35.815 24.9202 33.8183L33.7252 9.47665Z" fill="#005186"/>
  </g>
  <defs>
    <clipPath id="clip0_6_1270">
      <rect width="40" height="40" fill="white"/>
    </clipPath>
  </defs>
</svg>
     </span>
     </div>
     
   </div>
    
         </div>
    </div>
  )
}
