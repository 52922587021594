import React from 'react'
import { Helmet } from 'react-helmet'
import AnimatedBox from '../../components/AnimatedBox'
import './Extra.css'
export default function PrivacyPolicy() {
  return (
    <div>
    <Helmet>
    <title>ADR & ODR: The Future of Dispute Resolution</title>
  <meta name="description" content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era." />
  <meta name="keywords" content="dispute resolution global" />
    </Helmet>
    <div className='firstBreak extraPage'>
    <section className='landing_wrap'>
      {
        window.innerWidth > 600 ?<h1 style={{padding:"0 1.5em"}}>
      Privacy Policy
        </h1> : <h1>
        Privacy Policy
        </h1>
      }
          <AnimatedBox/>
      </section>
    </div>
   <div className='extra_section_wrap'>
    <h3>Privacy Policy</h3>
    <p>This Privacy Policy (“Policy”) is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000, and the rules made thereunder.
</p>
    <p>The terms “we / us / our / platform / Presolv360”, individually and collectively, refer to <a href="https://presolv360.com/">https://www.presolv360.com/;</a> and the terms “you / your / yourself / user” refer to any legal person or entity accessing the platform who is competent to enter into binding contracts, as per the provisions of the Indian Contract Act, 1872.  
</p>
<p>
This document is published and shall be construed in accordance with the provisions of the Information Technology Act, 2000 read with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, that require publishing of the privacy policy for collection, use, storage and transfer of sensitive personal data or information.
</p>
<p>
Please read this Policy carefully. By using the platform, you indicate that you understand, agree and consent to this Policy. If you do not agree with the terms of this Policy, please do not use the platform. The user expressly agrees that we retain the sole and exclusive right to amend or modify the Policy and the <a href="https://presolv360.com/terms_conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> without any prior permission or intimation to the user, and the user expressly agrees that any such amendments or modifications shall come into effect immediately.</p>
   
<h3>Introduction</h3>
<p>Presolv360 is an online dispute resolution platform built for quick, economical and effective resolution of your legal disputes. We have created an electronic arbitration module called as ‘Arbitration360’ and an electronic mediation module called as ‘Mediation360’ by blending technology, innovation and human expertise to bring dispute resolution right at your fingertips.
</p>
<p>We care about your privacy and protect the information that we collect from you when you use our platform. We handle the information collected from you in accordance with this Policy. By using our platform, you consent to the data collection practices described in this Policy. This Policy is only applicable to our platform, and not to any other platforms that you may be able to access from our platform or any of our partner website, each of which may have data collection, storage, and use practices and policies that differ materially from this Policy. Your use of the platform is governed by this Policy and the Terms. Each time you use the platform, the current version of this Policy will govern your use. Accordingly, each time you use our platform, you should check the date at the bottom of this Policy’s page to be sure that you’ve read the most recent version.
</p>
<p>
You unequivocally agree that this Policy and the Terms constitute a legally binding agreement between you and us, and that you shall be subject to the terms applicable to any service that is provided by the platform, and that the same shall be deemed to be incorporated into the Terms, and shall be treated as part and parcel of the same. You acknowledge and agree that no physical or electronic signature or express act is required to make this Policy and the Terms binding on you, and that your act of visiting any part of the platform constitutes the your full and final acceptance of this Policy and the Terms.</p>
   
   
   <h3>Collection, Purpose and Usage of Information</h3>
   <p>{`(See Rules 4 and 5 of The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011
`}</p>
<p>
We limit the collection and use of your information to the minimum, to deliver superior service to our users. We do not reveal your information to external organizations unless we have previously informed you in disclosures or agreements, have been authorized by you or are required by law. Below information may be collected from you :</p>
  <li className="boldBullet">Personal Information</li>
  <p>For the purpose of availing any services through our platform, you may have to provide us with basic personal information, including but not limited to first and last name, e-mail address, office or residential address, nationality, contact number, login credentials and passwords for the purpose of using the platform (individually and collectively hereinafter referred to as “personal information”). You are required to provide information about yourself that is true, accurate, current and complete in all respects.</p>
   
   <li className="boldBullet">Payment Information </li>
   <p>If you choose to avail any of our services through the platform, our third-party payment processors will collect your payment information. We do not store your payment information in our system.</p>
   <li className="boldBullet">Communication</li>
   <p>Communications you send to us include but not limited to requests for customer support or technical questions or any other communication in relation to the services provided through our platform.</p>
   <h5>Platform Usage Information</h5>
   <p>You are aware that our platform may automatically track certain information about the user based upon your IP address and behavior on the platform, and you expressly consents to the same. The user is aware that this information is used to do internal research on user demographics, interests, and behavior, to enable the platform to better understand, and cater to the interests of its users. The user is expressly made aware that such information may include the URL that the user visited prior to accessing the platform, the URL which the user subsequently visits (whether or not these URLs form a part of the platform), the user’s device and web browser information, the user’s IP address, etc.
</p>
<li className="boldBullet">Device Information</li>
<p>We may collect information about your mobile device / laptop / desktop / tablet, including but not limited to, the hardware model, operating system and version, software and file names and versions, preferred language, serial number, device motion information, mobile network information, etc. This shall be important to maintain the user experience and future performance of the platform.</p>
<li className="boldBullet">User Account</li>
<p>The user is aware that while you can browse some sections of the platform without being a registered user, certain activities (such as raising and resolving disputes) require the user to provide valid personal information to the platform for the purpose of registration.</p>
<li className="boldBullet">Cookies</li>
<p>Cookies are text information files that are placed on the visitor’s computer/ device when the visitor visits the platform. Our platform, as well as most sites on the Internet and mobile applications, use cookies to enhance user experience. The user is aware that the platform places both permanent and temporary cookies in the user’s computer's / device’s hard drive and web browser and does hereby expressly consent to the same. The user is further aware that the platform uses data collection devices such as cookies on certain pages of the platform to analyze page flow, measure promotional effectiveness, and promote trust and safety, and that certain features of the platform are only available using such cookies. While the user is free to decline the platform’s cookies if permitted by the platform, the user may consequently be unable to use certain features on the platform.</p>
<li className="boldBullet">Web Beacons</li>
<p>“Web Beacons” are tiny graphic image files imbedded in a web page or email that provide a presence on the web page or email and send back to its home server information from the user’s browser. In limited circumstances we also may use Web Beacons to collect anonymous, non- personal information about your use of our platform and your use of emails, special promotions we send to you. The information collected by web beacons allows us to statistically monitor how many people are using our platform or opening our emails, and for what purposes.</p>
<li className="boldBullet">Expertise</li>
<p> While the Platform providers possess relevant understanding of technology, design and law, the practitioners are subject to a comprehensive selection and empanelment process keeping in mind their qualification, competence, knowledge and expertise. The practitioners are required to complete all requisite trainings and certifications as may be prescribed by Presolv360 and are also recommended to periodically engage in various forms of continued education to refine and improve their knowledge and skills.
</p>
<li className="boldBullet">
Platform Analytics</li>
<p>We may use third party analytics services in connection with our platform. These analytical services do not collect personal information that you do not voluntarily enter on the platform. These services neither track your browsing habits across websites nor your usage of other mobile applications, which do not use their services. We are using the information collected from these services to find usability problems to make the platform easier to use. The recordings will never identify you or your personal information.</p>
<li className="boldBullet">
Sensitive Personal Data or Information</li>
<p>For the purpose of providing services through the platform, we may have the requirement to collect Sensitive Personal Data or Information (“SPDI”) from you while offering our services due to the nature of the services being offered. SPDI shall have the meaning in accordance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</p>
  <p>User information on the platform is collected and retained by Presolv360.
</p>
  <p>The user may review the information provided and ensure that any information found to be inaccurate or deficient is corrected or amended as feasible. However, we shall not be responsible for the authenticity of the information provided by the user.
</p>
  <p>The user shall, at any time, have an option to withdraw its consent for the collection of information contemplated herein. In such a case, the user shall write to us withdrawing consent by way of an email addressed to <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">info@presolv360.com</a>. Presolv360 shall, upon a request made to that effect by the user, erase and destroy all the data, record and correspondence collected, preserved or retained for the purpose of providing services through the platform, provided such data, record and correspondence is required to be retained and preserved or can be erased and destroyed beyond or before the said period, as the case may be, as per law or any court or authority.</p>
  <h3>Disclosure of Information</h3>
  <p>(See Rules 4 and 6 of The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011
  )</p>
  <p>
We may share your information :</p>
<ul>
    <li>In response to a request for information by a competent authority if we believe disclosure is in accordance with or is otherwise required by, any applicable law, regulation, or legal process;
</li>
    <li>With law enforcement officials, government authorities if we believe your actions are inconsistent with our Terms, other terms and conditions and this Policy, or to protect our rights, property, or safety or that of others;
</li>
    <li>If disclosure is intended to help investigate or prevent unauthorized transactions or other illegal activities;
</li>
    <li>If necessary or appropriate to protect our rights or property, or the rights or property of any person or entity;
</li>
    <li>If we otherwise notify you and you consent to the sharing;
</li>
    <li>We may share your de-identified personal information with third parties to enable them to run statistical research on individual or aggregate trends;
</li>
    <li>We share personal information and traffic data with our business partners who assist us by performing core services (such as consultations, maintenance of site, hosting, billing, fulfillment, or data storage and security) related to our operation of the platform and/or by making certain interactive tools available to our users. Those business partners shall be bound to uphold the same standards of security and confidentiality that we have promised to you in this Policy, and they will only use your personal information to carry out their specific business obligations to Presolv360.</li>
</ul>
<p>We do not sell, exchange, transfer or give your information or SDPI to third parties for any reason whatsoever except to the extent required for fulfilment of our services.</p>
  <h3>Rights under the GDPR</h3>
  <p>You have certain rights if you are within the European Union. These include :</p>
  <li className="boldBullet">Right to access</li>
  <p>This right allows you to obtain a copy of your personal data as well as other supplementary information.
</p>
  <li className="boldBullet">Right to restrict processing</li>
  <p>You have the right to restrict the processing of your personal data in certain circumstances.
</p>
  <li className="boldBullet">Right to rectification</li>
  <p>You have the right to have any incomplete or inaccurate information, we hold about you, corrected.
</p>
  <li className="boldBullet">Right to object to processing</li>

  <p>The right to object allows you to stop or prevent us from processing your personal data. This right exists where we are relying on a legitimate interest as the legal basis for processing your personal data. You also have the right to object where we are processing your personal data for direct marketing purposes.
</p>
  <li className="boldBullet">Right to erasure</li>
  <p>You have the right to ask us to delete or remove personal data when the personal data is no longer necessary for the purpose for which it was originally collected or processed.</p>
  
  <p>To exercise your rights, you can contact us at <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">info@presolv360.com</a>.</p>


  <li className="boldBullet">Rights under the CCPA</li>
  <p>Users who are California residents have certain rights under the California Consumer Privacy Act (“CCPA”). These rights include</p>
  <li className="boldBullet">Right to know</li>
  <p>You have the right to request to know more about the categories and specific pieces of personal information that we have collected about you and access a copy of your personal information.

</p>
  <li className="boldBullet">Right to deletion</li>
  <p>You have the right to restrict the processing of your personal data in certain circumstances.
</p>
  <li className="boldBullet">Right to rectification</li>
  <p>You have the right to request deletion of personal information that we have collected about you.

</p>
  <li className="boldBullet">Right to non-discrimination</li>

  <p>If you choose to exercise any of your rights under CCPA, we will treat you like all other users. In other words, there is no penalty for exercising your rights under CCPA.

</p>
  <li className="boldBullet">Right to opt-out</li>
  <p>We do not sell personal information.</p>
  
  <p>To exercise any of these rights under CCPA, please email <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">info@presolv360.com</a>.</p>
  <h3>Security Practices and Procedures</h3>
  <p>(See Rules 5(8) and 8 of The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011
)</p>
  <p>We have implemented appropriate managerial, technical, operational and physical procedures to safeguard the information of the user and to maintain data security. These safeguards take into account the sensitivity of the information that Presolv360 collects, process and stores and the current state of technology.
</p>
  <p>Our hosting services maintains its systems in accordance with reasonable industry standards to reasonably secure the information of its customers, such as using SSL encryption in certain places to prevent eavesdropping, and employing up-to-date software on the server. Presolv360 utilizes services of a third-party payments processor which employs appropriate safeguards for the purpose of payments made on the platform.
</p>
  <p>Presolv360 follows generally accepted industry standards to protect the information submitted to it. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect the information of the users, we cannot guarantee its absolute security.
</p>
  <p>Presolv360 assumes no liability or responsibility for disclosure of the user’s information due to errors in transmission, unauthorized third-party access, or other causes beyond our control.</p>
  <p>To know more, refer the Online Dispute Resolution <a href="https://presolv360.com/odr_standard" target="_blank" rel="noopener noreferrer">Standards</a> adopted by Presolv360.</p>
   
   <h5>Data Protection Officer</h5>
   <p>(See Rule 5(9) of The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011
)</p>
   <p>The name and contact details of the Data Protection Officer who can be contacted with respect to any complaints or concerns including those pertaining to breach of these terms contained in the Policy, and other polices or questions are published as under:</p>
   <p>Data Protection Officer Name: Mr. Bhaven Shah
</p>
<p>E-mail address: <a href="mailto:bhaven@presolv360.com" target="_blank" rel="noopener noreferrer">bhaven@presolv360.com</a> </p>
<h3>Miscellaneous</h3>
<p>While we may retain any correspondence sent by you, we do not intend to collect or save any information from children 18 years of age and younger. The platform is also not intended for users who are not competent to contract under the provisions of Indian Contract Act, 1872.
</p>
<p>We reserve the right to remove or edit content and refuse access to the platform at our sole discretion.</p>
   </div>

    </div>
  )
}
