import React from 'react'

export default function Case8() {
  return (
    <>
    <b>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
   </b>
   <div>
   <i>Case Title:  In Re Interplay Between Arbitration Agreements Under The Arbitration And Conciliation Act 1996 And The Indian Stamp Act 1899

  
   </i>
   <i>
   Court:  Supreme Court of India 
   </i>
   <i>Date of Judgement: 13.12.2023</i>
   </div>
    <p style={{paddingTop:"1.5em"}} >
    The Supreme Court of India, led by a seven-judge bench, made a significant ruling on December 13, 2023, declaring that arbitration clauses in unstamped or inadequately stamped agreements are enforceable. This decision overrules the earlier judgment by a five-judge bench in the case of M/s. N.N. Global Mercantile Pvt. Ltd. v. M/s. Indo Unique Flame Ltd. And Ors, which held that unstamped arbitration agreements were not enforceable.</p>  
   <b className='underlineText'>Kindly find the details Below</b> 
   
 
 
   <p style={{paddingBottom:"1.5em"}}>
The Hon’ble Court clarified that agreements lacking proper stamping are not void from the beginning i.e. void ab initio nor unenforceable; instead, they are simply inadmissible in evidence until the defect of non-stamping or inadequate stamping is cured. Additionally, the Hon’ble Court determined that objections regarding the stamping of agreements do not fall under the purview of Sections 8 or 11 of the Arbitration Act 1996, emphasizing that the courts must merely assess the prima facie existence of an arbitration agreement. Such stamping
issues, the Hon’ble Court noted, fall within the jurisdiction of the arbitral tribunal.</p>
   </>
  )
}
