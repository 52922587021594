import React from 'react'

export default function Case6() {
  return (
    <>
      <b style={{textAlign:"center"}}>Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act.  
   </b>
    <p style={{paddingTop:"1.5em"}} >
    On 25.10.2023, the Hon’ble Bombay High Court observed that objections regarding the non-stamping or insufficient stamping of an arbitration agreement need to be raised at the earliest before the arbitrator or at the first available opportunity. If not raised, such objections cannot be entertained later under Sections 34 or 37 of the Arbitration and Conciliation Act, 1996 (“Act”).
   </p>  
 
   <div>
   <i>Case Title: Azizur Rehman Gulam v. Radio Restaurant
   </i>
   <i>
   Court: Bombay High Court 
   </i>
   <i>Date of Judgement: 25.10.2023</i>
   </div>
     <p>The Hon’ble High Court while dealing with this issue and while providing this observation specifically referred to the case of Hemendra Rasiklal Ghia vs. Subodh Mody by stating that:
</p>
<p>
“even assuming the contention of insufficiency of stamp was a valid one, we find that since the same was never raised before the Arbitral Tribunal, the Judgment of this Court in the case of Hemendra Rasiklal would squarely apply”
 
</p>
   <p style={{paddingBottom:"1.5em"}}>
   The complete observation of the Hon’ble Court can be found on Page 46 of the Judgment.
   </p>
   </>
  )
}
