import React from 'react'
import './section.css'
import EnquiryModal from '../components/EnquiryModal';
import AnimatedBox from '../components/AnimatedBox';
import { NavLink } from 'react-router-dom';


export default function Landing() {


  return (
    <section className=' section landing_wrap'>
        <h1 data-aos="fade-right" data-aos-duration="1500">
        Online dispute resolution platform <br></br> to resolve legal disputes and <br></br> achieve settlements in record time
        </h1>
        <p data-aos="fade-left" data-aos-duration="1500">Easy. Efficient. Enforceable.</p>
        <div className='flex_row btn_wrp'>
        <NavLink  exact  to="/about-us"> 
        <button className='orange_btn' style={{minHeight:"42px"}}>
        Discover More
        </button>
        </NavLink>
       
     
        <EnquiryModal tittle={'Business Enquiry'} />
      
        </div>
        <AnimatedBox/>
    
      
    </section>
  )
}
