import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)


    // const goToNextPage = () => {
    //         if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    // }
    // const goToPrevPage = () => {
    //     if(currentPage !== 1) setCurrentPage(currentPage - 1)
    // }
    return (
        
            <ul className='pagination'>
                {/* <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                      >
                        
                        Previous
                    </a>
                </li> */}
                {pageNumbers.map(pgNumber => (
                    <li style={{cursor:"pointer"}} onClick={() => {
                        setCurrentPage(pgNumber)
                     window.scrollTo(0,window.innerHeight - 80)  
                }}   key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >

                        <div 
                            className='page-link' 
                           >
                            
                            {pgNumber}
                        </div>
                    </li>
                ))}
                {/* <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        >
                        
                        Next
                    </a>
                </li> */}
            </ul>
    
    )
}

export default Pagination